import { Component, OnInit, ViewChild } from '@angular/core';
import { FormConfigService, FormHelperService, PreinscriptionService, PermissionService, FamilyService } from '@app/services';
import { StepType } from '@app/forms/config/form-model';
import { FormViewerComponent, SubmitFormEvent } from '@app/components/_public/form-viewer/form-viewer.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, of, forkJoin } from 'rxjs';

@Component({
  selector: 'app-user-preinscription-edit',
  templateUrl: './preinscription-edit.component.html',
  styleUrls: ['./preinscription-edit.component.scss']
})
export class PreinscriptionEditComponent implements OnInit {

  id: number;
  step: string;
  readOnly: boolean;
  form: StepType[];
  data = {};
  data$: Observable<any>;
  isLoading = true;
  loadingMessage = 'Chargement';
  isNewPreinscription: boolean;

  formTitle: string;

  @ViewChild(FormViewerComponent) formViewer: FormViewerComponent;

  constructor(
    private formConfigService: FormConfigService,
    private helperService: FormHelperService,
    private preinscriptionService: PreinscriptionService,
    private permService: PermissionService,
    private familyService: FamilyService,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get('id');
    this.step = this.route.snapshot.paramMap.get('step');
    this.isNewPreinscription = this.route.snapshot.routeConfig.path === "add";

    this.data$ = this.preinscriptionService.getFormData(this.id, this.step);
    this.readOnly = !this.permService.hasPermission('preinscription_edit') || !this.familyService.currentFamily.active;

    this.formTitle = this.readOnly ? 'Voir pré-inscription' : 'Modifier pré-inscription';

    if (this.isNewPreinscription) {
      this.formTitle = this.route.snapshot.data.title;
    }

    forkJoin([
      this.formConfigService.getForm('form-pre-inscription', this.step),
      this.data$
    ])
      .subscribe(([form, data]) => {
        this.form = this.formConfigService.getFormView(form).filter(f => f.enabled);
        this.data = data;
        this.isLoading = false;
      });

  }

  onSave(event: SubmitFormEvent) {
    const data = event.model;
    this.formViewer.setErrorMessage('');

    if (data["deja_ne"] === 'Oui') {
      data["dateAccouchement"] = '';
    } else {
      data["dateNaissance"] = '';
    }

    this.loadingMessage = 'Enregistrement';
    if (this.readOnly) {
      return;
    }
    this.isLoading = true;
    const saveMethod = (this.id) ? this.preinscriptionService.update(data, this.id, this.step) : this.preinscriptionService.create(data);
    saveMethod.subscribe((result: any) => {
      this.helperService.displayDebugTraces(result.traces);
      this.helperService.notifySuccess('Modification effectuée',result.messages);  

      this.location.back();
    }, err => {
      this.isLoading = false;

      setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
        this.helperService.manageServerError(err,this.formViewer);
      })
    });

  }


}
