import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PortailTransLoader implements TranslateLoader {

  remoteUrl = 'conf/translation';

  storedTranslation = {};

  constructor(
    private http: HttpClient
  ) { }

  getTranslation(lang: string): Observable<any> {

    // @NB: could also use sessionStorage ?
    if (this.storedTranslation[lang]) {
      return of(this.storedTranslation[lang]);
    }

    return this.http.get(this.remoteUrl).pipe(
      tap(content => this.storedTranslation[lang] = content)
    );
  }

  save(modifiedTranslation) {
    return this.http.put(this.remoteUrl, modifiedTranslation);
  }
}
