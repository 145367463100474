<h1 mat-dialog-title>{{ title }}</h1>

<div mat-dialog-content *ngIf="data.template" class="thin-scrollbar">
  <ng-container [ngTemplateOutlet]="data.template"></ng-container>
</div>

<div mat-dialog-content *ngIf="data.message" [innerHTML]="data.message|safe:'html'" class="thin-scrollbar"></div>

<div mat-dialog-actions align="center">
  <button mat-raised-button color="primary" cdkFocusInitial [mat-dialog-close]>{{ confirmText }}</button>
</div>
