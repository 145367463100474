import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormConfigService, FormHelperService, PermissionService, FamilyService, UserService } from '@app/services';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { StepType } from '@app/forms/config/form-model';
import { forkJoin, of } from 'rxjs';
import { FormViewerComponent, SubmitFormEvent } from '@app/components/_public/form-viewer/form-viewer.component';
import { AdresseUpdateService } from '@app/services/adresse-update.service';
import { addressToUpdate } from '@app/models/address';
import { MatBottomSheet } from '@angular/material/bottom-sheet';


@Component({
  selector: 'app-foyer-edit',
  templateUrl: './foyer-edit.component.html',
  styleUrls: ['./foyer-edit.component.scss']
})
export class FoyerEditComponent implements OnInit {

  id: number;
  idUser: number;
  step: string;
  readOnly: boolean;

  form: StepType[];
  data: any;
  isLoading = true;
  loadingMessage = 'Chargement';

  formTitle: string;
  dataUpdateAddress: addressToUpdate;
  updateUserChecked: string;
  updateConjointChecked: string;
  conjointData: any;
  userData: any;
  manyAddressesDisplay: boolean;

  @ViewChild(FormViewerComponent) formViewer: FormViewerComponent;
  @ViewChild('updateAddressBottomSheet', { static: true }) updateAddressBottomSheet: TemplateRef<any>;

  constructor(
    private formConfigService: FormConfigService,
    private helperService: FormHelperService,
    private familyService: FamilyService,
    private route: ActivatedRoute,
    private location: Location,
    private permService: PermissionService,
    private userService: UserService,
    private addressUpdateService: AdresseUpdateService,
    public bottomSheet: MatBottomSheet,
  ) { }

  ngOnInit() {
    this.id = (this.familyService.currentFamily ? this.familyService.currentFamily.id : null) || null;
    this.step = this.route.snapshot.paramMap.get('step');
    this.readOnly = this.id && !this.permService.hasPermission('foyer_edit') || !this.familyService.currentFamily.active;
    this.idUser = this.userService.currentUser.id;
    this.familyService.currentFamilyReadyOnce$.subscribe(f => this.formTitle = 'Famille ' + f.nom);


    forkJoin([
      this.formConfigService.getForm('form-famille', this.step),
      this.familyService.getFormData(this.id, this.step),
      this.userService.getCurrentFamilyAdults()
    ]).subscribe(([form, data, adultes]) => {
      this.form = this.formConfigService.getFormView(form).filter(f => f.enabled);
      if (data["debiteurPrincipal"]) {
        this.updateResponsablesNamesInRadioLabels(this.form, adultes, data);
      }
      this.data = data;
      this.isLoading = false;
    });
  }

  updateResponsablesNamesInRadioLabels(form: StepType[], adultes: any[], data) {
    form.forEach(step => {
      if (step.stepName === "complement") {
        const fieldDebiteurPrincipal = this.formConfigService.findFieldByName(step.fields, "debiteurPrincipal");
        const fieldAllocataire = this.formConfigService.findFieldByName(step.fields, "allocataire");
        const resp1 = adultes.find(ad => ad.order === 1);
        const resp2 = adultes.find(ad => ad.order === 2);
        const resp1Label = resp1 ? resp1.nom + ' ' + resp1.prenom : 'Responsable 1';
        const resp2Label = resp2 ? resp2.nom + ' ' + resp2.prenom : 'Responsable 2';

        const updateFieldOptions = (options: any[], dataField: any) => {
          options.forEach(opt => {
            switch (opt.value) {
              case "1": opt.label = resp1Label; break;
              case "2": opt.label = resp2Label; break;
              case "3": opt.label = resp1Label + ' & ' + resp2Label; break;
            }

            if (adultes.length < 2) {
              dataField = (parseInt(dataField, 10) > 1) ? null : dataField;
              options.splice(1);
            }
          })
        }

        if (fieldAllocataire) {
          updateFieldOptions(fieldAllocataire.templateOptions.options as any[], data.allocataire)
        }

        if (fieldDebiteurPrincipal) {
          updateFieldOptions(fieldDebiteurPrincipal.templateOptions.options as any[], data.allocataire)
        }
      }
    });
  }

  checkAddress() {
    this.addressUpdateService.checkAddress('famille').subscribe((data: addressToUpdate) => {
      this.dataUpdateAddress = data;

      if ((data.userAddress && !data.userAddressIsSameFamilyAddress) ||
        (data.conjointAddress && !data.conjointAddressIsSameFamilyAddress)) {

        this.getUserData();
        this.getconjointData();
        this.bottomSheet.open(this.updateAddressBottomSheet);

        this.manyAddressesDisplay = this.addressUpdateService.manyAddressesDisplay(
          data.conjointAddress,
          data.userAddress,
          data.conjointAddressIsSameFamilyAddress,
          data.userAddressIsSameFamilyAddress)
      }
    });
  }

  getUserData() {
    this.userData = this.addressUpdateService.getDataUser(this.dataUpdateAddress);
  }

  getconjointData() {
    this.conjointData = this.addressUpdateService.getDataConjoint(this.dataUpdateAddress);
  }

  updateAddressUser() {
    this.addressUpdateService.updateAddressUser('foyer', this.dataUpdateAddress);
  }

  updateAddressConjoint() {
    this.addressUpdateService.updateAddressConjoint('foyer', this.dataUpdateAddress);
  }

  updateConjointUserAddresses() {
    this.addressUpdateService.updateAddresses('foyer', this.dataUpdateAddress);
  }

  validUpdate() {
    if (this.updateUserChecked === 'oui' && (this.updateConjointChecked === 'non' || !this.updateConjointChecked)) {
      this.updateAddressUser();
    }

    if ((this.updateUserChecked === 'non' || !this.updateUserChecked) && this.updateConjointChecked === 'oui') {
      this.updateAddressConjoint();
    }

    if (this.updateUserChecked === 'oui' && this.updateConjointChecked === 'oui') {
      this.updateConjointUserAddresses();
    }
  }

  onSave(event: SubmitFormEvent) {
    const data = event.model;
    this.formViewer.setErrorMessage('');
    this.loadingMessage = 'Enregistrement';
    if (this.readOnly) {
      return;
    }
    this.isLoading = true;
    const saveMethod = (this.id) ? this.familyService.update(data, this.id, this.step) : this.familyService.create(data);
    saveMethod.subscribe((result: any) => {
      this.helperService.displayDebugTraces(result.traces);
      this.helperService.notifySuccess('Modification effectuée',result.messages);  
      if (this.id) {
        if (this.step === "coordinates") {
          this.checkAddress();
        }
      }
      this.location.back();
    }, err => {
      this.isLoading = false;

      setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
        this.helperService.manageServerError(err,this.formViewer);
      })
    });
  }


  closeBottomSheet(value) {
    if (value) {
      this.bottomSheet.dismiss();
    }
  }
}
