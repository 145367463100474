<mat-toolbar mat-dialog-title color="primary" [style.background]="title.color"
  [style.color]="selectedPresence?.contrastColor" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
  [cdkDragDisabled]="platformService.isMobile">
  <button *ngIf="selectedPresence && presences" mat-icon-button (click)="returnToList()" type="button" class="reply">
    <mat-icon>reply</mat-icon>
  </button>
  <span class="title" [style.fontSize]="title.size ? title.size + '%' : ''">{{ title.text }}</span>
  <button class="close-dialog" mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<mat-dialog-content cdkScrollable
  class="mat-dialog-content thin-scrollbar {{ animClass }} {{ selectedPresence ? 'details-view' : 'list-view' }}">
  <ng-container [ngTemplateOutlet]="selectedPresence ? detailsView : listView"></ng-container>
</mat-dialog-content>

<div *ngIf="selectedPresence && editPath" mat-dialog-actions align="center">
  <button mat-raised-button color="accent-lighter" mat-dialog-close [routerLink]="editPath">
    {{ 'reservation.planning.details.access_reservation'|trans }}
  </button>
</div>

<ng-container *ngIf="selectedPresence && !readOnly">
  <div *ngIf="disableCancel !== 'cancel' && (!selectedPresence.id || planningData.currentPeriode?.allowCancel)"
    mat-dialog-actions align="center" style="margin-top: 5px;">
    <button mat-raised-button class="cancel-btn" color="warn" [matMenuTriggerFor]="cancelMenu"
      [disabled]="disableCancel"
      [matTooltip]="disableCancel ? (('reservation.planning.details.disable_cancel.' + disableCancel)|trans) : ''">
      <mat-icon>delete</mat-icon>
      <span *ngIf="periodeCancelLabel; else genericLabel">{{ periodeCancelLabel }}</span>
      <ng-template #genericLabel>
        {{ ('reservation.planning.details.' + (selectedPresence.id ? 'ask_cancel' : 'cancel'))|trans }}
      </ng-template>
    </button>
  </div>

  <mat-menu #cancelMenu="matMenu">
    <button mat-menu-item (click)="onClickCancel()">
      Cette demande
    </button>
    <button mat-menu-item (click)="onClickCancelMulti()">
      Plus de jours
    </button>
  </mat-menu>
</ng-container>

<div *ngIf="!selectedPresence" mat-dialog-actions align="center">
  <button *ngIf="addAction" mat-raised-button color="primary-lighter" (click)="onClickNew()">
    {{ ('reservation.planning.details.new_' + addAction)|trans }}
    <mat-icon>add_circle_outline</mat-icon>
  </button>
  <button *ngIf="canRecopy" mat-raised-button color="accent-lighter" (click)="onClickRecopy()">
    {{ 'reservation.planning.details.recopy_day'|trans }}
    <mat-icon>content_copy</mat-icon>
  </button>
</div>

<ng-template #detailsView>

  <div>
    <mat-icon matTooltip="Date">date_range</mat-icon>
    <span>
      Le {{ selectedPresence.date|date:'shortDate' }}
    </span>
  </div>

  <div *ngIf="selectedPresence.showTimes">
    <mat-icon matTooltip="Heure">access_time</mat-icon>
    <span>
      De {{ selectedPresence.startTime }} à {{ selectedPresence.endTime }}
      <ng-container *ngIf="selectedPresence.startTime2">
        et de {{ selectedPresence.startTime2 }} à {{ selectedPresence.endTime2 }}
      </ng-container>
    </span>
  </div>

  <div *ngIf="selectedPresence.activities && selectedPresence.activities.length">
    <app-icon type="do" icon="activite" matTooltip="Activités"></app-icon>
    <span class="activity-list">
      <span *ngFor="let act of selectedPresence.activities">
        <a href="javascript:void(0)" (click)="openActivityDetails(act)">{{ act.label || act.name }}</a>
        <span *ngIf="act.listeAttente" style="margin-left: 5px">(sur liste d'attente)</span>
      </span>
    </span>
  </div>

  <div>
    <mat-icon matTooltip="Statut">{{ ('reservation.state_icon.' + selectedPresence.status)|trans }}</mat-icon>
    <span class="{{ selectedPresence.colorClass }}">
      {{ ('reservation.state_text.' + selectedPresence.status)|trans }}
    </span>
  </div>

  <div *ngIf="selectedPresence.reservation?.otherAccount">
    <mat-icon>{{ 'reservation.state_icon.other_account'|trans }}</mat-icon>
    <span>{{ 'reservation.state_text.other_account'|trans }}</span>
  </div>

  <hr>

  <h4 class="section-title">
    <mat-icon>arrow_right</mat-icon>
    Réservation
  </h4>

  <div *ngIf="selectedPresence.reservation">
    <mat-icon matTooltip="Réservation">info</mat-icon>
    <span>
      {{ selectedPresence.reservation.fromDomino ? 'Réservation Domino' :
        (selectedPresence.reservation.number ? 'N° ' + selectedPresence.reservation.number : 'Nouvelle réservation') }}

      <span *ngIf="selectedPresence.reservation.creationDate">
        (le {{ selectedPresence.reservation.creationDate|date:'dd/MM/yyyy' }})
      </span>
    </span>
  </div>

  <div *ngIf="selectedPresence.consumer">
    <i *ngIf="selectedPresence.consumer.idEnfant>0" class="icon icodomino-enfant" matTooltip="Enfant"></i>
    <i *ngIf="selectedPresence.consumer.idAdulte>0" class="icon"
      [ngClass]="{'icodomino-man': selectedPresence.consumer.sexe === 'M' , 'icodomino-woman': selectedPresence.consumer.sexe === 'F'}"
      matTooltip="Adulte"></i>
    <span>{{ selectedPresence.consumer.prenom }} {{ selectedPresence.consumer.nom }}</span>
  </div>

  <div *ngIf="selectedPresence.reservation?.etablissement">
    <i class="icon icodomino-etablissement" matTooltip="Etablissement"></i>
    <span>{{ selectedPresence.reservation.etablissement }}</span>
  </div>

  <div *ngIf="selectedPresence.reservation?.accueil">
    <i class="icon icodomino-accueil" matTooltip="Accueil"></i>
    <span>{{ selectedPresence.reservation.accueil }}</span>
  </div>

  <div *ngIf="selectedPresence.reservation?.periode">
    <i class="icon icodomino-periode" matTooltip="Période"></i>
    <span>{{ selectedPresence.reservation.periode }}</span>
  </div>

  <!-- <ng-container *ngIf="selectedPresence.rubrique as rub">
    <div *ngIf="rub.enabled">
      <app-icon type="do" icon="rubrique-planning" matTooltip="Rubrique"></app-icon>
      <span>{{ rub.label || rub.name }}</span>
    </div>
  </ng-container> -->

  <!-- @TODO: update with "motifRefus" ? -->
  <div *ngIf="selectedPresence.reason">
    <mat-icon matTooltip="Observation">notes</mat-icon>
    <span>{{ selectedPresence.reason }}</span>
  </div>
</ng-template>

<ng-template #listView>
  <app-presence-list *ngIf="presences?.length" [presences]="presences" (clickPresence)="onClickPresence($event)">
  </app-presence-list>

  <p *ngIf="!presences.length" class="empty-list">
    Aucune présence sur cette journée.
  </p>
</ng-template>
