import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CogitoPreinscription, ConfigCogito } from '@app/models/cogito';
import { UsagerChild } from '@app/models/consumer';
import { Family } from '@app/models/family';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { ChildAdapter } from './adapter/child-adapter.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CogitoService {

  configUrl = 'conf/cogito';

  constructor(
    private http: HttpClient,
    private childAdapter: ChildAdapter
  ) { }

  getConfigForAdmin() {
    return this.http.get<{ config: ConfigCogito, listAnneesScolaires: [], listNiveauxScolaires: [] }>('conf/admin/cogito');
  }

  getConfigForUser() {
    return this.http.get(this.configUrl);
  }

  saveConfig(config: any) {
    return this.http.put(this.configUrl, { config }, httpOptions);
  }

  getFamilyPreinscriptions(f: Family | number) {
    const idFamily = typeof f === 'object' ? f.id : f;
    return this.http.get(`familles/${idFamily}/cogito-preinscriptions`);
  }

  getPreinscription(f: Family | number, id: number) {
    const idFamily = typeof f === 'object' ? f.id : f;

    return this.http.get<CogitoPreinscription>(`familles/${idFamily}/cogito-preinscriptions/${id}`);
  }

  savePreinscription(preinscription: CogitoPreinscription, idFamily: number) {
    return this.http.post<{ errors?: [] }>(`familles/${idFamily}/cogito-preinscriptions`, { preinscription });
  }

  getOptions(family: Family | number) {
    const idFamily = typeof family === 'object' ? family.id : family;

    return this.http.get(`familles/${idFamily}/cogito/options`).pipe(
      map((data: any) => {
        data.childList.forEach(u => {
          u = this.childAdapter.adapt(u);
        });

        return data;
      })
    );
  }

  checkPermInscription(config: ConfigCogito) {
    const start = moment(config.startDate).isValid() ? moment(config.startDate) : null;
    const end = moment(config.endDate).isValid() ? moment(config.endDate).add(1, 'day') : null;
    const currentDateTime = moment(new Date());

    if ((start && end && currentDateTime.isBetween(start, end))
      || (!end && currentDateTime.isAfter(start))
      || (!start && currentDateTime.isBefore(end))
      || !start && !end) {
      return true;
    };

    return false;
  }

}
