import { Component, OnInit, TemplateRef, ViewChild, ElementRef, Inject, Optional, QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { PlatformService, SnackbarService, FamilyService, UserService, ChildService, PermissionService } from '@app/services';
import { GedService } from '@app/services/ged.service';
import { GedDocument, GedPiece, GedConfig } from '@app/models/ged';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';
import { forkJoin, Subscription, Subject } from 'rxjs';
import { Family } from '@app/models/family';
import { tap, switchMap, takeUntil, filter } from 'rxjs/operators';
import { Adulte } from '@app/models/adulte';
import { DomSanitizer } from '@angular/platform-browser';
import { Child } from '@app/models/child';

interface GedEntity {
  id: number;
  name: string;
  type: string;

  error?: string;
  documents?: GedDocument[];
}

interface GedData {
  pieces: GedPiece[];
  entities: GedEntity[];
  documents: GedDocument[];
}

const iconClasses = {
  image: ['jpg', 'jpeg', 'png'],
  pdf: ['pdf'],
  word: ['doc', 'docx']
};

@Component({
  selector: 'app-ged',
  templateUrl: './ged.component.html',
  styleUrls: ['./ged.component.scss']
})
export class GedComponent implements OnInit, OnDestroy {

  config: GedConfig;

  knownTypes = ['famille', 'enfant', 'adulte']; // order matters
  dataByType: { [type: string]: GedData } = {};
  firstType: string;

  showInvalideFile = false;

  previewURL: any;
  downloadFile: any;

  uploadingFile: File;
  uploadDialogRef: MatDialogRef<any>;
  uploadRequest: Subscription;
  errorMessage: string;

  uploadProgress: number = null;
  documentDownload: { [doc: number]: number } = {};

  loaded = false;
  loadingPreview = false;

  onDestroy$ = new Subject();

  @ViewChild('fileUploadDialog', { static: true }) fileUploadDialog: TemplateRef<any>;
  @ViewChild('pieceDetailsDialog', { static: true }) pieceDetailsDialog: TemplateRef<any>;
  @ViewChild('documentDetails', { static: true }) documentDetailsDialog: TemplateRef<any>;

  @ViewChildren('inputFile') inputFile: QueryList<ElementRef>;

  constructor(
    public platformService: PlatformService,
    private gedService: GedService,
    private matDialog: MatDialog,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private familyService: FamilyService,
    private sanitizer: DomSanitizer,
    private childService: ChildService,
    public permService: PermissionService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {

    this.familyService.currentFamily$.pipe(
      filter(f => !!f),
      takeUntil(this.onDestroy$),
      tap(() => this.loaded = false),
      switchMap(f => this.loadGedData())
    ).subscribe(() => this.loaded = true);
  }

  loadGedData() {
    return forkJoin([
      this.gedService.getConfig(),
      this.gedService.getCurrentFamilyDocuments(),
      this.userService.getCurrentFamilyAdults(),
      this.childService.getCurrentFamilyChildren()
    ]).pipe(tap(([config, familyDocuments, adults, children]) => {

      this.config = config;
      const familyDocs = familyDocuments;
      const family = this.familyService.currentFamily;

      this.knownTypes.forEach(type => {
        const pieces = config.listePiecesAFournir.filter(piece => piece.obj.toLowerCase() === type);

        if (pieces && pieces.length) {
          const entities = this.parseEntities(type, { family, adults, children });
          const documents = this.parseDocuments(type, familyDocs);

          this.dataByType[type] = { pieces, entities, documents };

          this.firstType = this.firstType || type;
        }
      });

      this.refreshErrors();
    }));
  }

  parseEntities(type, data: { family: Family, adults: Adulte[], children: Child[] }): GedEntity[] {
    if (type === 'enfant') {
      return data.children.map(c => ({ id: c.id, name: c.prenom + ' ' + c.nom, type }));
    }

    if (type === 'famille') {
      return [{ id: data.family.id, name: data.family.civilite + ' ' + data.family.nom, type }];
    }

    if (type === 'adulte') {
      return data.adults.map(a => ({ id: a.id, name: a.prenom + ' ' + a.nom, type }));
    }
  }

  parseDocuments(type: string, documents: GedDocument[]) {
    const idKey = 'id' + type.charAt(0).toUpperCase() + type.slice(1);
    const now = new Date().getTime();

    return documents.filter(doc => doc[idKey]).map(doc => ({
      ...doc, idEntite: doc[idKey], valid: now < new Date(doc.dateFinValidite).getTime()
    }));
  }

  isPieceMandatory(piece: GedPiece) {
    return piece.pieceJointeObligatoire || piece.accueils.find(pacc => pacc.pieceJointeObligatoire);
  }

  getDocumentsFor(documents: GedDocument[], piece: GedPiece, entity: GedEntity) {
    const idKey = entity.type === 'famille' ? 'idFamille' : 'id' + entity.type.charAt(0).toUpperCase() + entity.type.slice(1);

    return documents.filter(doc => doc.idPieceAFournir === piece.id && doc[idKey] === entity.id);
  }

  getEntityErrors(entity: GedEntity) {
    const documents = this.dataByType[entity.type].documents;
    const mandatoryPieces = this.dataByType[entity.type].pieces.filter(p => this.isPieceMandatory(p));

    const errorPieces = mandatoryPieces.filter(p => !this.getDocumentsFor(documents, p, entity).length);

    return errorPieces.length ? 'Documents manquants pour les pièces suivantes : \n' + errorPieces.map(p => p.name).join(',') : null;
  }

  refreshDocuments(documents: GedDocument[]) {
    this.knownTypes.forEach(type => {
      if (this.dataByType.hasOwnProperty(type)) {
        this.dataByType[type].documents = this.parseDocuments(type, documents);
      }
    });
  }

  refreshErrors() {
    Object.keys(this.dataByType).forEach(type => {
      this.dataByType[type].entities.forEach(e => e.error = this.getEntityErrors(e));
    });
  }

  onFileSelect(fileList: FileList, entite: GedEntity, piece: GedPiece) {
    if (fileList.length) {
      this.uploadingFile = fileList[0];
      this.checkDocuments(fileList);
      this.loadPreview(this.uploadingFile);
      this.openFileUploadDialog(entite, piece);
    }
  }

  onToggleInvalideFile() {
    this.showInvalideFile = !this.showInvalideFile;
  }

  loadPreview(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      if (reader.result && (file.type.startsWith('image/') || file.type.endsWith('pdf'))) {
        this.previewURL = reader.result;
      }
    };
  }

  openPieceDetailsDialog(piece: GedPiece) {
    const data = {
      piece,
      mandatoryAccueils: piece.accueils.filter(acc => acc.obligatoire),
      optionalAccueils: piece.accueils.filter(acc => !acc.obligatoire)
    };

    const dial = this.matDialog.open(this.pieceDetailsDialog, { data, maxWidth: 600 });

    this.platformService.adaptDialogToScreen(dial);
  }

  openFileUploadDialog(entite, piece) {
    this.uploadDialogRef = this.matDialog.open(this.fileUploadDialog, {
      data: { entite, piece },
      maxWidth: 600
    });

    this.platformService.adaptDialogToScreen(this.uploadDialogRef);

    this.uploadDialogRef.afterClosed().subscribe(_ => {
      this.uploadProgress = null;
      this.previewURL = null;
      this.errorMessage = null;
      this.inputFile.map(input => input.nativeElement.value = null);
    });
  }

  getExtension(fileName: string) {
    return fileName.split('.').pop().toLowerCase();
  }

  getFileIcon(doc: GedDocument) {
    const extension = doc.nomFichier.split('.').pop().toLowerCase();

    return Object.keys(iconClasses).find(key => iconClasses[key].includes(extension));
  }

  checkExtensionStartsWith(fileName: string, nameStart) {
    return this.getExtension(fileName).startsWith(nameStart);
  }

  checkDocuments(fileList: FileList) {
    const uploadingFileExtension = this.getExtension(fileList[0].name);
    const fileConfigSize = +this.config.size * 1048576;
    const fileConfigExtension = this.config.extensions.toLocaleLowerCase().split(' ');

    if (!fileConfigExtension.includes(uploadingFileExtension) && !this.config.extensions.includes('*')) {
      this.errorMessage = `Ce type de fichier n\'est pas autorisé. Fichiers autorisés : ${this.config.extensions}.`;
    }

    if (this.uploadingFile.size > fileConfigSize) {
      this.errorMessage = `Fichier trop volumineux. Le fichier ne doit pas dépasser ${this.config.size}Mo.`;
    }
  }

  cancelUpload() {
    if (this.uploadingFile && this.uploadRequest) {
      // Should really abort the upload ...
      this.uploadRequest.unsubscribe();
    }

    this.uploadDialogRef.close();
  }

  onUploadSubmit(entite: GedEntity, piece: GedPiece) {
    let progressUpdatedAt = Date.now();
    this.uploadProgress = 0;

    this.uploadRequest = this.gedService.sendDocument(this.uploadingFile, piece, entite.id).subscribe({
      next: event => {
        const now = Date.now();
        // Manual throttleTime
        // Other way might be a Progress subject (because this one is not only for Progress) paused until ProgressBar.animationEnd
        if (event.type === 'up_progress' && event.value > this.uploadProgress && (event.value === 100 || now - progressUpdatedAt > 300)) {
          this.uploadProgress = event.value as number;
          progressUpdatedAt = now;
        }
      },
      error: err => this.snackbarService.error('Erreur lors de l\'envoi du fichier !'),
      complete: () => {
        this.uploadDialogRef.close();
        this.snackbarService.info('Fichier envoyé !');

        // Reload family documents & refresh stuff
        this.gedService.getCurrentFamilyDocuments().subscribe(data => {
          this.refreshDocuments(data);
          this.refreshErrors();
        });
      }
    });
  }

  openDocDetails(doc: GedDocument) {
    const piece = this.config.listePiecesAFournir.find(p => p.id === doc.idPieceAFournir);
    const data = { doc, piece };

    const dialog = this.matDialog.open(this.documentDetailsDialog, { data });
    this.platformService.adaptDialogToScreen(dialog);

    // If preview possible, load file from API ...
    const fileExtension = this.getExtension(doc.nomFichier);
    if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'pdf'].includes(this.getExtension(doc.nomFichier))) {
      this.loadingPreview = true;
      this.gedService.getDocument(doc.idDocumentElectronique).subscribe(resp => {
        if (resp.type === 'response') {
          if (fileExtension === 'pdf') {
            (resp.value as Blob).arrayBuffer().then(buf => this.previewURL = buf);
          } else {
            this.previewURL = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(resp.value));
          }

          this.loadingPreview = false;
        }
      });
    }

    dialog.afterClosed().subscribe(_ => {
      window.URL.revokeObjectURL(this.previewURL);
      this.previewURL = null;
    });
  }

  downloadDocument(doc: GedDocument) {
    this.gedService.getDocument(doc.idDocumentElectronique).subscribe(resp => {
      if (resp.type === 'dl_progress') {
        this.documentDownload[doc.idDocumentElectronique] = resp.value as number;
      } else if (resp.type === 'response') {
        this.openBlob(doc, resp.value);
      }
    }, err => this.snackbarService.error('Erreur lors de l\'ouverture du fichier !'));
  }

  // @see https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api
  // => keep this reference, if needs IE support ...
  openBlob(doc: GedDocument, blob: Blob) {
    const blobURL = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobURL;
    link.download = doc.nomFichier;

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
      this.documentDownload[doc.idDocumentElectronique] = null;
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  }

  deleteDocument(doc: GedDocument) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: { message: `Voulez vous supprimer le fichier : ${doc.nomFichier} ?` },
      maxWidth: 500
    });

    this.platformService.adaptDialogToScreen(dialogRef);

    dialogRef.afterClosed().subscribe(closeCode => {
      console.log('Close code : ', closeCode);

      if (closeCode) {
        this.gedService.delete(doc.idDocumentElectronique).subscribe(res => {
          this.snackbarService.info('Document supprimé');

          // delete locally
          this.deleteLocalDoc(doc.idDocumentElectronique);
        });
      }
    });
  }

  deleteLocalDoc(id: number) {
    this.knownTypes.forEach(type => {
      this.dataByType[type].documents.forEach((x, index) => {
        if (x.idDocumentElectronique === id) {
          this.dataByType[type].documents.splice(index, 1);
        }
      });
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
