import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HoursData, Periode, PeriodeRubrique, ReservationPeriode } from '@app/models/periode';
import { PlatformService } from '@app/services';
import { DATE_FORMAT, PlanningService } from '@app/services/planning.service';
import { PlanningData } from '../planning-data';
import { ReservationPresence } from '@app/models/reservation';
import moment from 'moment';

const defaultTimeLimits = { min: '09:00', max: '18:00' };

@Component({
  selector: 'app-mikado-new-presence',
  templateUrl: './mikado-new-presence.component.html',
  styleUrls: ['./mikado-new-presence.component.scss']
})
export class MikadoNewPresenceComponent implements OnInit {

  data: PlanningData;
  date: moment.Moment;
  existing: ReservationPresence;

  times: HoursData = { start: '00:00', end: '00:00' };

  periode: ReservationPeriode;
  rubrique: PeriodeRubrique;

  limits: { min: string, max: string } = defaultTimeLimits;

  // to check for hour conflicts
  otherPresences: ReservationPresence[];

  error?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<MikadoNewPresenceComponent>,
    private planningService: PlanningService,
    public platformService: PlatformService
  ) {
    this.date = moment(data.date);
    this.data = data.planningData;
    this.existing = data.presence;
  }

  ngOnInit(): void {
    this.periode = this.data.currentPeriode as ReservationPeriode;
    this.rubrique = this.periode.rubriques.find(r => r.id === this.periode.idRubrique);

    this.initTimeBounds();

    if (this.existing) {
      this.times.start = this.existing.startTime;
      this.times.end = this.existing.endTime;
    } else {
      this.times.start = this.limits.min;
      this.times.end = this.limits.max;
    }

    const currentDate = this.date.format(DATE_FORMAT);

    this.otherPresences = this.planningService.filterPresences(
      this.data.getCurrentConsumerPresences().filter(pr => pr.date === currentDate &&
        (!this.existing || !this.planningService.isSamePresence(pr, this.existing)))
    );
  }

  initTimeBounds() {
    // @NB: tried to make something more sofisticated, but hard, and pointless in first time as there's no need ...
    const dateStr = this.date.format(DATE_FORMAT);

    if (this.periode.agrements) {
      const agrement = this.periode.agrements.find(ag => this.planningService.isDateBetween(dateStr, ag.startDate, ag.endDate));

      if (agrement) {
        const limits = agrement.days.find(agd => agd.day === this.date.isoWeekday());

        if (limits) {
          this.limits = { min: limits.min.substr(0, 5), max: limits.max.substr(0, 5) };
        } else {
          console.warn('Impossible de définir les horaires de l\'établissement pour la date ' + dateStr);
        }
      }
    } else {
      console.warn('Aucun horaire établissement');
    }
  }

  validate() {
    this.refreshErrors();

    if (!this.error) {
      this.dialogRef.close(this.times);
    } else {
      console.error('Error : ', this.error);
    }
  }

  refreshErrors() {
    this.error = this.validateTime(this.times.start) || this.validateTime(this.times.end);

    if (this.error) {
      return;
    }

    if (this.otherPresences.some(pr => this.planningService.checkTimesOverlap(
      [{ start: pr.startTime, end: pr.endTime }, { start: pr.startTime2, end: pr.endTime2 }],
      [{ start: this.times.start, end: this.times.end }]
    ))) {
      this.error = 'conflict';
    }
  }

  onChangeTime() {
    this.refreshErrors();
  }

  validateTime(value: string) {
    if (value < this.limits.min || value > this.limits.max) {
      return 'etablissement_times_constraint';
    }

    const minute = +(value.split(':').pop());

    if (this.periode.intervalleMinHoraire && (minute % this.periode.intervalleMinHoraire !== 0)) {
      return 'minutes_interval';
    }

    return null;
  }
}
