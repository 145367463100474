import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BoardConfig } from '@app/models/board-card';
import { ApiCrudService } from './api-crud.service';
import { map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

type BoardType = 'user' | 'home' | 'assmat';

@Injectable({ providedIn: 'root' })
export class BoardConfigService extends ApiCrudService<BoardConfig> {

  url = 'conf/board';

  private cache = {};

  constructor(
    protected http: HttpClient
  ) {
    super();
  }

  set(type: BoardType, config) {
    return super.update({ config }, type);
  }

  getComponent(type: BoardType, componentName: string) {
    return this.get(type).pipe(
      map(conf => conf.cards.find(card => card.component === componentName))
    );
  }

  get(type: BoardType) {
    return this.cache[type] ? of(this.cache[type]) : super.get(type).pipe(tap(c => this.cache[type] = c));
  }
}
