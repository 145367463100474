export class User {
  id: number;

  username: string;
  password: string;
  email?: string;

  firstName: string;
  lastName: string;

  role: string; // we can store the role for the session, but keep careful about it (maybe re send a login check on each page reload)
  token?: string;
  sessionExpiration?: Date;

  idAdulte: number;
  idAssmat?: number;
  accountType?: AccountType;
}

export type AccountType = 'assmat' | 'family';

// See which one better .. ?
// export enum AccountType {
//   AssMat = 'AssMat',
//   Adulte = 'Adulte'
// }

// @TODO: move somewhere else
export enum Role {
  Authenticated = 'Authenticated',
  User = 'User',
  Manager = 'Manager',
  Admin = 'Admin'
}

export const ROLES_HIERARCHY = {
  [Role.User]: [Role.Authenticated],
  [Role.Manager]: [Role.User],
  [Role.Admin]: [Role.Manager]
};
