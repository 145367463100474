import { Component, OnDestroy, OnInit } from '@angular/core';
import { BoardConfigService, PlatformService, FamilyService, AuthenticationService, UserService } from '@app/services';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { BoardCard, BoardConfig } from '@app/models/board-card';
import { forkJoin, of, Subject } from 'rxjs';
import { AssmatService } from '@app/services/assmat.service';
import { User } from '@app/models/user';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

  boardCards: BoardCard[];
  accountVars: object;
  loading = false;
  destroy$ = new Subject();
  userValue: User;

  constructor(
    private boardConfigService: BoardConfigService,
    private familyService: FamilyService,
    private accountService: AuthenticationService,
    private adulteService: UserService,

    public platformService: PlatformService,
    private assmatService: AssmatService
  ) { }

  ngOnInit() {
    this.accountService.accountTypeChange$.pipe(
      tap(_ => this.loading = true),
      takeUntil(this.destroy$),
    ).subscribe(typeAccount => {

      if (typeAccount === "assmat") {
        setTimeout(() => {
          forkJoin([
            this.boardConfigService.get('assmat'),
            this.assmatService.getTypeEtablissementsEnCoursByIdAssmat(this.accountService.currentUserValue.idAssmat)
          ]).subscribe(([config, typeEtabs]: [BoardConfig, any]) => {
            const boardCards = config.cards;

            this.filterBoardCardsByTypeEtabsEnCoursAssmat(typeEtabs, boardCards);

            this.loading = false;
          })
        });
      }

      if (typeAccount === "family") {
        this.boardConfigService.get('user').subscribe(config => {
          config.cards?.forEach(card => {
            if (card.component === 'reservations') {
              if (this.accountService.currentUserValue.idAdulte < 1) {
                card.disabledCause = 'reservation.info.invalide_account_message';
              } else if (!!this.adulteService.currentAdulte && !this.adulteService.currentAdulte.emailConfirm) {
                card.disabledCause = 'Votre email doit être confirmé';
              }
            }
          });
          this.boardCards = config.cards.filter(card => card.enabled);
          this.loading = false;
        })
      }

    })

    // Could add these info to basic familyData (that we get right after login), but conflicts with local storage ...
    this.familyService.currentFamilyChange$.pipe(
      takeUntil(this.destroy$),
      switchMap(fam => fam ? this.familyService.getFamilyVariables(fam.id) : of({}))
    ).subscribe((vars: any) => {
      this.accountVars = vars;
      this.familyService.reservationsToPay$.next(vars.reservationsEnAttentePaiement);
    });
  }

  filterBoardCardsByTypeEtabsEnCoursAssmat(typeEtab, boardCards) {
    if (!typeEtab?.mentalo && typeEtab?.mikado) {
      this.boardCards = boardCards.filter(card => card.component !== "contrat-accueil-mentalo" &&
        card.component !== "animations" &&
        card.component !== "reunions" &&
        card.enabled);
    }
    if (!typeEtab?.mikado && typeEtab?.mentalo) {
      this.boardCards = boardCards.filter(card => card.component !== "assmat-pointage" && card.enabled);

    }
    if (typeEtab?.mentalo && typeEtab?.mikado) {
      this.boardCards = boardCards.filter(card => card.enabled);
    }

    if ((!typeEtab?.mentalo && !typeEtab?.mikado) || !typeEtab) {
      this.boardCards = boardCards.filter(card => (
        card.component !== "assmat-pointage" &&
        card.component !== "contrat-accueil-mentalo" &&
        card.component !== "reunions" &&
        card.component !== "animations" &&
        card.enabled))
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
