<mat-card *ngIf="!homeCard" class="conteneur">
  <mat-card-header>
    <mat-card-title>Connexion</mat-card-title>
    <mat-icon mat-card-avatar class="icon">person</mat-icon>
  </mat-card-header>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</mat-card>

<div *ngIf="homeCard">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #content>
  <ng-container *ngIf="authService.currentUser$|async as user; else loginBlock">

    <p>{{ 'Logged in as'|trans }} {{ user.username || user.email }}</p>

    <button mat-raised-button color="primary" routerLink="/account">Mon compte</button>
  </ng-container>
</ng-template>

<ng-template #loginBlock>

  <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
    <div fxLayout="row" fxLayoutAlign="center center" class="login-error warn-bg" *ngIf="error" translate>
      <mat-icon style="margin-right: 5px;">error</mat-icon> <span>Nom d'utilisateur ou mot de passe incorrect.</span>
    </div>

    <div fxLayout="column" class="content">
      <mat-form-field>

        <mat-label>Adresse e-mail</mat-label>

        <input type="email" matInput formControlName="username" autocomplete="username" [appFocusOnShow]="!homeCard"
          name="portailfamilles_login" />

        <mat-error *ngIf=" !loginForm.controls.username.valid || !loginForm.controls.username.untouched ">
          <span translate>Nom d'utilisateur requis</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input type="password" matInput formControlName="password" autocomplete="current-password"
          placeholder="Mot de passe" required name="portailfamilles_password" />
        <mat-error *ngIf=" !loginForm.controls.password.valid || !loginForm.controls.password.untouched ">
          <span translate>Mot de passe requis</span>
        </mat-error>
      </mat-form-field>

      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <mat-slide-toggle formControlName="remember" color="primary">{{'remember_me'|trans}}</mat-slide-toggle>
        <a href="#" routerLink="/forgot-password" style="margin: 10px; cursor: pointer;" translate>
          forgot_your_password
        </a>
      </div>

      <button id="btnLogin" mat-raised-button color="primary" type="submit" [disabled]="isLoading">
        <span translate>login</span>
        <app-loader size="1" class="" *ngIf="isLoading"></app-loader>
      </button>

      <div class="new" *ngIf="permService.hasPermission('account_create')">
        <span class="mat-h3"> {{'register_label'|trans}} </span>
        <button type="button" id="btn-register" mat-button color="accent" href="#" routerLink="/register">
          {{'register_button'|trans}}
        </button>
      </div>

    </div>

  </form>
</ng-template>
