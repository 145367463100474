import { Injectable } from '@angular/core';
import { BaseAdapterInterface } from './base-adapter.service';
import { StepType, ExtendedFormlyFieldConfig } from '@app/forms/config/form-model';
import { clone } from '@app/utils/object';

interface ExtraConfig {

  [additionalProperties: string]: {
    key?: string;
    toKey?: string;
    simplified?: boolean;
    defaultConfig?: ExtendedFormlyFieldConfig;
    function?: (field: ExtendedFormlyFieldConfig, reverse: boolean) => any | void
  };

}

export const radioYesNoOptions = [
  { value: 'Oui', label: 'Oui' },
  { value: 'Non', label: 'Non' }
];

@Injectable({
  providedIn: 'root'
})
export class FormConfigAdapterService implements BaseAdapterInterface<any> {

  enfantMap: ExtraConfig = {
    //  BlocVacc: {
    //    key: 'defaultValue',
    //    simplified: true,
    // //   defaultConfig: {
    // //     type: 'vaccin'
    // //   }
    // },
    // Bloc_maladie: {
    //   key: 'fieldGroup',
    //   simplified: true,
    //   defaultConfig: {
    //     type: 'ouiNon',
    //     // className: 'col-3',
    //     disabledPlaceholder: true,
    //     templateOptions: {
    //       appearance: 'standard',
    //       options: radioYesNoOptions
    //     }
    //   }
    // },
    // Bloc_allergie: {
    //   key: 'fieldGroup',
    //   simplified: true,
    //   defaultConfig: {
    //     type: 'allergie'
    //   }
    // }
  };

  preinscriptionMap: ExtraConfig = {
    // questionnaireFamille: {
    //   key: 'fieldGroup',
    //   simplified: true,
    //   function: this.parseQuestions
    // }
  };

  constructor() { }

  getConfigForName(name: string) {
    const configs = {
      'form-enfant': this.enfantMap,
      'form-pre-inscription': this.preinscriptionMap
    };

    return name in configs ? configs[name] : null;
  }

  adapt(data: any, name?: string) {
    const extraConfig = this.getConfigForName(name);

    return this.parseConfig(data, extraConfig, false);
  }

  reverse(serverData: any, name?: string): StepType[] {
    const extraConfig = this.getConfigForName(name);

    return this.parseConfig(serverData, extraConfig, true);
  }

  parseConfig(data: ExtendedFormlyFieldConfig[] | any, extraConfig: ExtraConfig, reverse: boolean = false) {
    if (!extraConfig) {
      return data;
    }

    const cloneConfig = clone(data);

    for (const field of cloneConfig) {
      this.parseConfigField(field, extraConfig, reverse);
    }

    return cloneConfig;
  }

  parseConfigField(field: ExtendedFormlyFieldConfig, extraConfig: ExtraConfig, reverse: boolean = false) {
    if ('fields' in field) {
      field.fields.forEach(x => this.parseConfigField(x, extraConfig, reverse));
    }
    // Pour aller dans les fieldGroup, mais bon, déjà bien assez lourd / foireux ... -.-
    //  else if ('fieldGroup' in field) {
    //   field.fieldGroup.forEach(x => this.parseConfigField(x, extraConfig, reverse));
    // }

    const key = field.key as string;

    if (key in extraConfig) {
      const rowExtraConf = extraConfig[key];
      const realField = rowExtraConf.key ? field[rowExtraConf.key] : field;

      const parsedConfig = this.treatExtraConfig(realField, rowExtraConf, reverse);

      if (rowExtraConf.toKey && rowExtraConf.key) {
        delete field[(reverse ? rowExtraConf.toKey : rowExtraConf.key)];
      }

      const targetKey = (reverse ? rowExtraConf.key : (rowExtraConf.toKey || rowExtraConf.key));

      if (targetKey) {
        field[targetKey] = parsedConfig;
      } else {
        field = parsedConfig;
      }
    }
  }

  treatExtraConfig(fieldConfig: ExtendedFormlyFieldConfig | any, extraConfig: ExtraConfig[any], reverse) {

    if (fieldConfig instanceof Array) {
      return fieldConfig.map(x => this.treatExtraConfig(x, extraConfig, reverse));
    }

    if (extraConfig.defaultConfig && !reverse) {
      fieldConfig = { ...fieldConfig, ...extraConfig.defaultConfig };
      fieldConfig.templateOptions = { ...extraConfig.defaultConfig.templateOptions, ...fieldConfig.templateOptions };
    }

    if (extraConfig.simplified) {
      fieldConfig = reverse ? this.collapseSimpleFields(fieldConfig) : this.expandSimpleFields(fieldConfig);
    }

    if (extraConfig.function) {
      fieldConfig = extraConfig.function.apply(this, [fieldConfig, reverse]);
    }

    if (extraConfig.defaultConfig && reverse) {
      for (const key in extraConfig.defaultConfig) {
        if (key in fieldConfig) {
          delete fieldConfig[key];
        }
      }
    }

    return fieldConfig;
  }

  collapseSimpleFields(field: ExtendedFormlyFieldConfig) {
    return {
      key: field.key,
      type: field.type,
      hide: field.hide,
      configTitle: field.configTitle,
      label: field.templateOptions.label,
      required: field.templateOptions.required,
      placeholder: field.templateOptions.placeholder
    };
  }

  expandSimpleFields(field: ExtendedFormlyFieldConfig) {

    field.templateOptions = field.templateOptions || {};
    field.templateOptions.label = field.label || field.configTitle;
    field.templateOptions.required = field.required;
    field.templateOptions.placeholder = field.placeholder;
    field.type = field.type || 'input'; // By default ;o
    field.hide = field.hide || false;
    return field;
  }

  // parseQuestions(question: ExtendedFormlyFieldConfig | any, reverse: boolean) {
  //   question.type = this.getQuestionType(question.type, reverse);

  //   if (question.type === 'radio') {
  //     question.templateOptions = question.templateOptions || {};
  //     question.templateOptions.options = radioYesNoOptions;
  //     question.templateOptions.appearance = 'standard';
  //   }

  //   return question;
  // }

  // getQuestionType(type: string, reverse: boolean) {
  //   const types = {
  //     chaine: 'input',
  //     booleen: 'radio',
  //     date: 'datepicker'
  //   };

  //   return reverse ? Object.keys(types).find(k => types[k] === type) : (type in types ? types[type] : type);
  // }
}
