<app-form-viewer *ngIf="!isLoading && formTitle; else loader" [readOnly]="readOnly" [formFields]="form"
  [model]="formData" (save)="onSave($event)" [formTitle]="formTitle">
</app-form-viewer>

<ng-template #loader>
  <div style="width: 100%; height: 50vh; display:flex;">
    <app-loader [message]="loadingMessage" style="text-align: center;">
    </app-loader>
  </div>
</ng-template>
