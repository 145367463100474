<div class="custom-links-container">

  <ng-container *ngFor="let linkItem of (previewMode ? linkItems : linkService.linkItems$|async)">
    <ng-container *ngIf="linkItem.enabled && showToUser(linkItem) && isActivePage(linkItem)">

      <ng-container *ngIf="linkItem.type === 'link'" [ngTemplateOutlet]="customLink"
        [ngTemplateOutletContext]="{link: linkItem}"></ng-container>

      <ng-container *ngIf="linkItem.type === 'category'" [ngTemplateOutlet]="category"
        [ngTemplateOutletContext]="{category: linkItem}"></ng-container>

    </ng-container>
  </ng-container>

</div>

<ng-template #category let-cat="category">
  <mat-card class="custom-link-block" [portailCustomStyle]="getCatBodyStyle(cat)">
    <mat-card-title *ngIf="cat.showTitle" [portailCustomStyle]="getCatTitleStyle(cat)" [matTooltip]="cat.tooltip"
      matTooltipTouchGestures="off">
      <app-icon *ngIf="cat.iconFontawesome || cat.iconDomino" [icon]="cat.iconFontawesome ? cat.iconFontawesome : cat.iconDomino"
        [type]="cat.iconDomino ? 'do' : (!cat.typeIcon && cat.iconFontawesome) ? 'fa' : cat.typeIcon"
        class="icon"
        style="margin-right: 5px;">
      </app-icon>
      {{ cat.text }}
    </mat-card-title>

    <ng-container *ngFor="let link of cat.links" [ngTemplateOutlet]="customLink"
      [ngTemplateOutletContext]="{link: link}"></ng-container>
  </mat-card>
</ng-template>

<ng-template #customLink let-link="link">
  <a #dir="portailCustomStyle" *ngIf="link.enabled && showToUser(link) && isActivePage(link)" [href]="link.url || ''"
    [matTooltip]="link.title" matTooltipTouchGestures="off"
    class="custom-link no-underline-animation{{link.class ? ' ' + link.class : ''}}"
    (click)="onCustomLinkClick(link, $event)" (touchstart)="onOver(link, dir)" (touchend)="onLeave(link, dir)"
    (mouseover)="onOver(link, dir)" (mouseleave)="onLeave(link, dir)"
    [ngClass]="(hovering === link && link.customHover ? link.hover.class : '')" [portailCustomStyle]="link.style">
    <app-icon *ngIf="link.iconFontawesome || link.iconDomino" [icon]="link.iconFontawesome ? link.iconFontawesome : link.iconDomino"
      [type]="link.iconDomino ? 'do' : (!link.typeIcon && link.iconFontawesome) ? 'fa' : link.typeIcon"
      style="margin-right: 10px;">
    </app-icon>
    {{ link.text }}
  </a>
</ng-template>
