<div class="conteneur" *ngIf="infosFoyer && userData && listKeysFieldForm; else loader">

  <div fxLayout="row wrap">

    <mat-card class="card" fxFlex="48.5" fxFlex.sm="48" fxFlex.xs="100" *ngIf="userData">
      <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title fxLayout="row" fxLayoutAlign="start center">
          <i *ngIf="userData.sexe === 'M'" style="margin-right: 5px;" class="icodomino-man"></i>
          <i *ngIf="userData.sexe === 'F'" style="margin-right: 5px;" class="icodomino-woman"></i> Moi
        </mat-card-title>
        <button style="margin-bottom: 20px;" mat-mini-fab color="primary" matTooltip="Voir" routerLink="/account/user">
          <mat-icon>visibility</mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content style="margin-left: 20px;">
        <div>
          <h4 style="margin-top: 5px; margin-bottom: 5px;">
            {{ userData.civilite  }}
            {{ userData.nom | uppercase }}
            {{ userData.prenom }}
            <span *ngIf="userData.situationFamiliale" class="sub">({{ userData.situationFamilialeName }})</span>
          </h4>
          <p style="margin-top: 2px;">
            <span *ngIf="userData.rue2">{{ userData.rue2 }}<br></span>
            <span *ngIf="userData.rue3">{{ userData.rue3 }}<br></span>
            <span>{{ userData.numRue }} {{ userData.rue }}<br></span>
            <span>{{ userData.codePostal }} {{ userData.ville }}</span>
          </p>
          <p fxLayout="row" fxLayoutAlign="start center">
            <mat-icon style="margin-right: 5px;">alternate_email</mat-icon><span>{{userData.email}}</span>
          </p>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon *ngIf="userData.telephone || userData.telMobile" style="margin-right: 5px;">local_phone
            </mat-icon>
            <div>
              <span *ngIf="userData.telephone"> - {{ userData.telephone }} <br></span>
              <span *ngIf="userData.telMobile"> - {{ userData.telMobile }}</span>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="card" fxFlex="48.5" fxFlex.sm="48" fxFlex.xs="100" *ngIf="userData">
      <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title fxLayout="row" fxLayoutAlign="start center">
          <i *ngIf="conjoint && conjoint.sexe === 'M'" style="margin-right: 5px;" class="icodomino-man"></i>
          <i *ngIf="conjoint && conjoint.sexe === 'F'" style="margin-right: 5px;" class="icodomino-woman"></i>
          <span>{{ 'foyer.other_responsible'|trans }}</span>
        </mat-card-title>
        <button *ngIf="conjoint" style="margin-bottom: 20px;" mat-mini-fab color="primary"
          [matMenuTriggerFor]="menuConjoint">
          <mat-icon>{{editFoyer ? 'edit' : 'visibility'}}</mat-icon>
        </button>
      </mat-card-header>

      <mat-menu class="mat-menu-conjoint" #menuConjoint="matMenu" xPosition="before">
        <button *ngFor="let formPart of adulteFormSteps" mat-menu-item
          (click)="openForm('/conjoint',formPart.stepName)">
          <mat-icon color="primary">{{editFoyer ? 'edit' : 'visibility'}}</mat-icon>
          {{formPart.label}}
        </button>
      </mat-menu>

      <mat-card-content *ngIf="conjoint" style="margin-left: 20px;">
        <div>
          <h4 style="margin-top: 5px; margin-bottom: 5px;">
            {{ conjoint.civilite }} {{ conjoint.nom | uppercase }} {{ conjoint.prenom  }}

            <span *ngIf="infosFoyer.situationFamiliale" class="sub">({{ infosFoyer.situationFamilialeName }})</span>
          </h4>
          <p style="margin-top: 2px;">
            <span *ngIf="conjoint.rue2">{{ conjoint.rue2 }}<br></span>
            <span *ngIf="conjoint.rue3">{{ conjoint.rue3 }}<br></span>
            <span>{{ conjoint.numRue }} {{ conjoint.rue }}<br></span>
            <span>{{ conjoint.codePostal }} {{ conjoint.ville }}</span>
          </p>
          <p fxLayout="row" fxLayoutAlign="start center" *ngIf="conjoint.email">
            <mat-icon style="margin-right: 5px;">alternate_email</mat-icon><span>{{conjoint.email}}</span>
          </p>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon *ngIf="conjoint.telephone || conjoint.telMobile" style="margin-right: 5px;">local_phone
            </mat-icon>
            <div>
              <span *ngIf="conjoint.telephone"> - {{ conjoint.telephone }} <br></span>
              <span *ngIf="conjoint.telMobile"> - {{ conjoint.telMobile }}</span>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions align="center" *ngIf="!conjoint">
        <button mat-fab color="primary" style="margin: 10px;" matTooltip="Ajouter un responsable"
          (click)="openForm('conjoint')" [disabled]="!editFoyer">
          <mat-icon>add</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card *ngFor="let step of foyerFormSteps" class="card" fxFlex="48.5" fxFlex.sm="48" fxFlex.xs="100">
      <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title fxLayout="row" fxLayoutAlign="start center">
          <app-icon *ngIf="step.stepName === 'coordinates'" style="margin-right: 5px; padding-top: 3px" type="do"
            icon="courrier">
          </app-icon>
          <mat-icon *ngIf="step.stepName === 'complement'" style="margin-right: 5px;">assignment</mat-icon>
          <span>{{ step.label }}</span>
        </mat-card-title>
        <button style="margin-bottom: 20px;" mat-mini-fab color="primary"
          [matTooltip]="editFoyer ? 'Voir/Modifier' : 'Voir'" (click)="openForm('foyer',step.stepName)">
          <mat-icon>{{editFoyer ? 'edit' : 'visibility'}}</mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content style="margin-left: 20px;">
        <ng-container *ngIf="step.stepName === 'coordinates'" [ngTemplateOutlet]="coordinates"></ng-container>
        <ng-container *ngIf="step.stepName === 'complement'" [ngTemplateOutlet]="complement"></ng-container>
      </mat-card-content>
    </mat-card>

  </div>
</div>


<ng-template #complement>
  <div>
    <p *ngIf="infosFoyer.situationFamiliale && listKeysFieldForm.includes('situationFamiliale')"
      style="margin-top: 5px;">
      <b>Situation familiale : </b> {{ infosFoyer.situationFamilialeName }}
    </p>
    <p *ngIf="infosFoyer.numAllocataire && listKeysFieldForm.includes('numAllocataire')"><b>N° Allocataire :
      </b>{{infosFoyer.numAllocataire}}</p>
    <p *ngIf="infosFoyer.nbTotalEnfants && listKeysFieldForm.includes('nbTotalEnfants')"><b>Nombres total d'enfants :
      </b>{{infosFoyer.nbTotalEnfants}}</p>
    <p *ngIf="infosFoyer.nbEnfantsACharge && listKeysFieldForm.includes('nbEnfantsACharge')"><b>Nombres d'enfants à
        charge : </b>{{infosFoyer.nbEnfantsACharge}}</p>
  </div>
  <mat-icon class="more-horiz" (click)="openForm('foyer','complement')">more_horiz</mat-icon>
</ng-template>

<ng-template #coordinates>
  <div>
    <h4 style="margin-top: 5px; margin-bottom: 5px;">
      {{ infosFoyer.civilite }}
      {{ infosFoyer.nomFamille | uppercase }}
    </h4>
    <p style="margin-top: 2px;">
      <span *ngIf="infosFoyer.rue2">{{ infosFoyer.rue2 }}<br></span>
      <span *ngIf="infosFoyer.rue3">{{ infosFoyer.rue3 }}<br></span>
      <span>{{ infosFoyer.numRue }} {{ infosFoyer.rue }}<br></span>
      <span>{{ infosFoyer.codePostal }} {{ infosFoyer.ville }}</span>
    </p>
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon *ngIf="infosFoyer.telephone2 || infosFoyer.telephone" style="margin-right: 5px;">local_phone
      </mat-icon>
      <div>
        <span *ngIf="infosFoyer.telephone"> - {{ infosFoyer.telephone }}</span><br>
        <span *ngIf="infosFoyer.telephone2"> - {{infosFoyer.telephone2}}</span>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>
