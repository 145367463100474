<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" [cdkDragDisabled]="platformService.isMobile">
  <mat-toolbar mat-dialog-title color="accent-lighter" cdkDragHandle>
    <h3 class="title">
      <mat-icon>paste</mat-icon>
      Outil de recopie
    </h3>
    <button mat-icon-button class="close-dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content class="thin-scrollbar" [class.single-view]="singleStep">

  <mat-tab-group *ngIf="!singleStep" dynamicHeight mat-stretch-tabs [(selectedIndex)]="activeTab">
    <mat-tab>
      <ng-template matTabLabel>
        <mat-icon color="primary-darker">content_copy</mat-icon> 1 - Présences source
      </ng-template>

      <ng-container *ngTemplateOutlet="sourceSettings"></ng-container>
    </mat-tab>

    <mat-tab>
      <ng-template matTabLabel>
        <mat-icon color="primary-darker">date_range</mat-icon> 2 - Dates cibles
      </ng-template>

      <ng-container *ngTemplateOutlet="targetSettings"></ng-container>
    </mat-tab>
  </mat-tab-group>

  <ng-container *ngIf="singleStep">
    <h3 class="section-title" style="margin: 0 auto .5em">
      <mat-icon color="primary">date_range</mat-icon>
      Dates cibles
    </h3>

    <ng-container *ngTemplateOutlet="targetSettings"></ng-container>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions [class.single-step]="singleStep">
  <ng-container *ngIf="!singleStep && activeTab === 0">
    <button mat-raised-button color="primary" (click)="activeTab = (activeTab + 1)" [disabled]="!presences?.length">
      Suivant
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </ng-container>
  <div *ngIf="singleStep || activeTab === 1" class="flex-1 row">
    <button mat-raised-button color="primary" (click)="activeTab = (activeTab - 1)">
      <mat-icon>arrow_back</mat-icon>
      Précédent
    </button>
    <div class="spacer"></div>
    <button mat-raised-button color="primary" (click)="validate()" [disabled]="!singleStep && !presences?.length">
      <mat-icon>check</mat-icon>
      Valider
    </button>
  </div>
</mat-dialog-actions>

<ng-template #sourceSettings>
  <div *ngIf="usagers?.length > 1" class="select-usager row items-center">
    <span style="flex: 1">Recopier le planning de</span>

    <mat-form-field class="no-hint" style="flex: 2; max-width: 70vw;">
      <mat-select [(ngModel)]="config.usager" (ngModelChange)="onChangeUsager()">
        <mat-option *ngFor="let usager of usagers" [value]="usager.id">
          {{ usager.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="select-source-dates row items-center">
    <span style="flex: 1">Pour</span>

    <mat-form-field class="no-hint" style="margin-right: 20px; width: 150px">
      <mat-select [(ngModel)]="config.mode" (ngModelChange)="onChangeDateMode()">
        <mat-option value="day">Le jour</mat-option>
        <mat-option value="week">La semaine</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="config.mode === 'day'" class="no-hint" style="width: 160px;">
      <mat-label>Le</mat-label>
      <input matInput [(ngModel)]="config.fromDate" (ngModelChange)="onUpdateDay()" [matDatepicker]="fromPicker"
        [matDatepickerFilter]="filterDateWithPresences">
      <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
      <mat-datepicker #fromPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngIf="config.mode === 'week'" class="no-hint"
      style="width: 250px; font-size: 14px; flex: 2; max-width: 300px;">
      <mat-select [(ngModel)]="selectedWeek" (ngModelChange)="onUpdateWeek()">
        <mat-option *ngFor="let week of weeks" [value]="week.id">
          {{ week.from|date:'ww'}} : du {{ week.from|date:'dd/MM/Y' }} au {{ week.to|date:'dd/MM/Y' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-container *ngIf="presences?.length; else noPresenceToCopy">
    <ng-container *ngIf="config.mode === 'week'; else normalList">
      <ng-container *ngFor="let date of presencesByDate">
        <div *ngIf="date.presences?.length">
          <label>{{ date.date|date:'EEE dd MMM'|titlecase }}</label>

          <ng-container *ngTemplateOutlet="presenceList; context: {items: date.presences}"></ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noPresenceToCopy>
    <p class="no-presence-copy">Aucune présence à copier.</p>
  </ng-template>

  <ng-template #normalList>
    <ng-container *ngTemplateOutlet="presenceList; context: {items: presences}"></ng-container>
  </ng-template>

  <ng-template #presenceList let-items="items">
    <div *ngFor="let presence of items" class="presence-row" [style.background]="presence.color"
      [style.color]="presence.contrastColor" [ngClass]="{'other-account': presence.reservation.otherAccount}">
      <mat-icon class="status-icon" [matTooltip]="('reservation.state_text.' + presence.status)|trans">
        {{ ('reservation.state_icon.' + presence.status)|trans }}
      </mat-icon>
      <div *ngIf="presence.title" class="presence-title">
        <b>{{ presence.title }}</b>
        <i *ngIf="presence.activities && presence.activities.length" class="icon icodomino-activite activities"
          matTooltip="+ {{ presence.activities.length }} activités"></i>

        <span *ngIf="presence.showTimes" class="presence-hours">
          {{ presence.startTime }} - {{ presence.endTime }}
          <span *ngIf="presence.startTime2">
            & {{ presence.startTime2 }} - {{ presence.endTime2 }}
          </span>
        </span>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #targetSettings>
  <div class="target-dates">

    <mat-form-field class="no-hint">
      <mat-label>Du</mat-label>
      <input matInput [(ngModel)]="config.startDate" [matDatepicker]="startPicker"
        (ngModelChange)="onUpdateTargetDates()" [min]="minTargetDate" [max]="maxTargetDate">
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="no-hint">
      <mat-label>Au</mat-label>
      <input matInput [(ngModel)]="config.endDate" [matDatepicker]="endPicker" (ngModelChange)="onUpdateTargetDates()"
        [min]="minTargetDate" [max]="maxTargetDate">
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <mat-datepicker #endPicker></mat-datepicker>
    </mat-form-field>

    <button mat-raised-button color="primary" [matMenuTriggerFor]="prefillMenu">
      <mat-icon>settings</mat-icon>
    </button>

    <mat-menu #prefillMenu yPosition="below">
      <ng-container *ngFor="let interval of commonIntervals">
        <button mat-menu-item *ngIf="interval" (click)="prefillDates(interval, $event)">
          {{ ('reservation.planning.recopy.interval.' + interval)|trans }}
        </button>
        <mat-divider *ngIf="!interval"></mat-divider>
      </ng-container>
    </mat-menu>
  </div>

  <div class="days-block">
    <label>Sur les jours :</label>

    <div style="margin: .5em .5em 0;">
      <span *ngFor="let weekDay of weekDays; let i = index" class="day">
        <mat-checkbox [(ngModel)]="config.weekDaysIndexed[i + 1]" [disabled]="!availableWeekdays.includes(i + 1)"
          (ngModelChange)="onUpdateTargetDates()">
          {{ weekDay|titlecase }}
        </mat-checkbox>
      </span>
    </div>
  </div>

  <div class="week-step">
    Répéter 1 semaine sur
    <mat-form-field class="no-hint">
      <mat-select [(ngModel)]="config.weekStep" (ngModelChange)="onUpdateTargetDates()">
        <mat-option *ngFor="let number of [1, 2, 3, 4]" [value]="number">
          {{ number }} {{ number === 1 ? '(chaque semaine)' : '' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <h3 class="section-title" style="margin: 0 0 7px">
      <mat-icon color="primary-darker">visibility</mat-icon>
      Aperçu des dates ciblées
    </h3>

    <app-simple-date-calendar [start]="config.startDate" [end]="config.endDate" [highlightDates]="targetDates"
      class="thin-scrollbar">
    </app-simple-date-calendar>
  </div>
</ng-template>
