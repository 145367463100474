import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ViewChild, Inject, Optional } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
// import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';
import { Reglement } from '@app/models/facturation';
import { FacturationService, PlatformService } from '@app/services';
import { forkJoin } from 'rxjs';
import { FactureDetailComponent } from '../../detail/detail.component';

interface ReglementWithDetails extends Reglement {
  idReglement: number;
  debiteur: string;
  login: string;
  regie: string;
  numPiece: string;
  factures: string[];
  reste: number;
  startDate: string;
  returnDate: string;
  notificationDate: string;
  validationDate: string;
  annulationDate: string;
  notes: string;
  detailsFacture: string;
  isTest: boolean;
}

@Component({
  selector: 'app-reglement-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class ReglementDetailsComponent implements OnInit {

  dateTimeFormat = 'dd/MM/yyyy - HH:mm:ss';
  displayedColumns: string[] = ['usager', 'designation', 'prixUnitaire', 'quantite', 'montant'];
  dataSource = new MatTableDataSource<any>();
  factureDetails: Object;
  loadFactureDetails: boolean;
  typePaiement: string;
  motif: string;

  @Input() reglement: ReglementWithDetails;

  @Output() validate = new EventEmitter();
  @Output() cancel = new EventEmitter();

  @ViewChild('detailFacture') detailFactureDialog: TemplateRef<any>;
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<any>;
  @ViewChild('history') history: TemplateRef<any>;


  action = false;

  constructor(
    private dialog: MatDialog,
    public platformService: PlatformService,
    private facturationService: FacturationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    if (!this.isSuperviseur() && this.reglement.detailsFacture) {
      this.factureDetails = JSON.parse(this.reglement.detailsFacture);
      this.dataSource = this.factureDetails['_TabBoLigneFacture'];
    }
    this.setTypePaiement();
  }

  setTypePaiement() {
    // if(this.reglement.isTest){
    //   this.typePaiement = "Paiement Test"
    // }
    if (this.factureDetails && !this.reglement.factures.length) {
      this.typePaiement = this.factureDetails['_TabBoLigneFacture'][0]['_Accueil'] ? "Paiement d'un panier de réservation" : "Paiement d'un achat de Ticket";
    } else if (!this.reglement.detailsFacture && this.reglement.factures.length) {
      this.typePaiement = "Paiement facture(s) existante(s)"
    }
  }

  getFactureDetails(idFacture: number, idRegie: number) {
    this.loadFactureDetails = true;
    forkJoin({
      facture: this.facturationService.getFactureDetails(idFacture),
      regie: this.facturationService.getRegieConfig(idRegie)
    }).subscribe((result) => {
      this.dialog.open(FactureDetailComponent, {
        data: result
      })
      this.loadFactureDetails = false;
    })
  }

  validatePayment() {
    // No way here to know when action will end / refresh will be done
    // (or maybe in onChanges, anyway the whole list (including this component) will be reloaded)
    const ref = this.dialog.open(this.confirmDialog, {
      height: 'auto',
      data: {
        title: 'Validation paiement',
        type: 'valid',
        message: `Confirmer la validation du paiement '<b>${this.reglement.numPiece}<b>' ?`
      }
    });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.action = true;
        this.validate.emit(this.reglement.id);
      }
    })
  }

  cancelPayment() {
    const ref = this.dialog.open(this.confirmDialog, {
      height: 'auto',
      data: {
        title: 'Annulation paiement',
        idReglement: this.reglement.idReglement,
        numero: this.reglement.numero,
        type: 'cancel',
        message: `Confirmer l'annulation du paiement '<b>${this.reglement.numPiece}<b>' ?`
      }
    });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.action = true;
        this.cancel.emit({id:this.reglement.id, motif: this.motif});
      }
    })
  }

  openDialogDetailFacture() {
    this.dialog.open(this.detailFactureDialog, {
      width: '900px'
    });
  }

  openDialogHistory() {
    this.dialog.open(this.history)
  }

  isSuperviseur(): Boolean {
    return this.reglement.login === 'SUPERVISEUR' ? true : false;
  }
}
