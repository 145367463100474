import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AdresseAutocompleteService } from '@app/services/adresse-autocomplete.service';
import { debounceTime, filter, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AddressApiMode } from '@app/models/global-config';
import { FormConfigService, GlobalConfigService } from '@app/services';

@Component({
  selector: 'app-adresse-autocomplete',
  templateUrl: './adresse-autocomplete.component.html',
  styleUrls: ['./adresse-autocomplete.component.scss']
})
export class AdresseAutocompleteComponent extends FieldType implements OnInit {

  results$: Observable<any>;
  apiMode: AddressApiMode;
  toggleEnabledField: boolean;
  fieldSearchDisabled: boolean;
  fieldsToFill: {};

  constructor(
    private adressAutocomplete: AdresseAutocompleteService,
    private globalConfigService: GlobalConfigService,
    private formConfigService: FormConfigService
  ) {
    super();
    this.globalConfigService.config$.subscribe(conf => this.apiMode = conf.addressApiMode);
  }

  ngOnInit() {

    if (this.form.get("adresseUrgenceAutocomplete")) {
      this.fieldSearchDisabled = this.form.get("adresseUrgenceAutocomplete").disabled;
    }

    if ('fieldsToFill' in this.to) {
      this.fieldsToFill = this.to.fieldsToFill;
    }

    if (this.apiMode === AddressApiMode.DOMINO) {
      for (const key of Object.keys(this.fieldsToFill)) {
        if (key !== "housenumber") {
          const field = this.form.get(this.fieldsToFill[key]);
          if (field instanceof AbstractControl) {
            field.disable();
          }
        }
      }
    }


    this.results$ = this.formControl.valueChanges.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      filter(term => term && term.trim()),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((adresse: string) => this.adressAutocomplete.searchAddress(adresse))
    );
  }

  optionSelected(address) {

    if (address.properties) {
      address = address.properties;
    }

    if (this.fieldsToFill) {
      for (const key of Object.keys(this.fieldsToFill)) {

        const field = this.form.get(this.fieldsToFill[key]);

        if (field instanceof AbstractControl) {
          const val = this.getElementFromGouvApiAddress(address, key) || '';
          field.setValue(val);
        }
      }
    }

  }

  private getElementFromGouvApiAddress(address, elementName) {

    if (elementName in address) {
      return address[elementName];
    }

    // Should work, adapt if needed
    if (address.type === elementName) {
      return address.name;
    }
  }

  onEnter(event: KeyboardEvent) {
    event.stopPropagation();
    return;
  }

  editFields() {
    for (const key of Object.keys(this.fieldsToFill)) {
      if (key !== "housenumber") {
        const field = this.form.get(this.fieldsToFill[key]);
        if (field instanceof AbstractControl) {
          if (this.toggleEnabledField) {
            field.enable();
            field.reset();
            this.formControl.disable();
          } else {
            field.disable();
            this.formControl.enable();
          }
        }
      }
    }

  }
}
