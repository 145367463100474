import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExtendedFormlyFieldConfig, ExtendedFormlyFormOptions } from '@app/forms/config/form-model';
import { FormConfigService } from '@app/services';
import { clone } from '@app/utils/object';

@Component({
  selector: 'app-formly-suggestion-dialog',
  templateUrl: './suggestion-dialog.component.html',
  styleUrls: ['./suggestion-dialog.component.scss']
})
export class SuggestionDialogComponent implements OnInit {
  form: FormGroup;
  model: {};
  fields: ExtendedFormlyFieldConfig;
  options: ExtendedFormlyFormOptions;
  // Suspend submit while form init, to avoid too early validation, and error "Expression has changed after it was checked"
  suspendSubmit = true;

  constructor(
    public dialogRef: MatDialogRef<SuggestionDialogComponent>,
    private formConfigService: FormConfigService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({});
    this.model = clone(this.data.field.model);
    this.fields = clone(this.data.field.fieldGroup);

    this.formConfigService.parseAllFields(this.fields, (field) => {
      if (field.format) {
        setTimeout(() => this.formConfigService.applyFormatter(field));
      }
    })

    if (this.data.options.formState.disabled || this.data.readOnly) {
      setTimeout(() => this.form.disable());
    } else {
      setTimeout(() => {
        this.suspendSubmit = false;
        if (this.data.field.formControl.invalid) { this.markAllTouched(this.form as FormGroup); }
      });
    }
  }

  onEnter(event: KeyboardEvent) {
    const target = event.target as HTMLElement;
    if (target.localName === 'textarea') {
      return;
    }
    if (this.data.options.formState.disabled) {
      return;
    }
    this.validate();
  }

  // Mark all fields as "touched" to notice the user of fields with error, even ones he didn't touch (mostly required)
  validate() {
    if (!this.form.valid) {
      this.markAllTouched(this.form as FormGroup);
    }
  }

  markAllTouched(formGroup: FormGroup | FormArray) {

    const controls = formGroup instanceof FormArray ?
      formGroup.controls : Object.keys(formGroup.controls).map(k => formGroup.controls[k]);

    controls.forEach(control => {
      control.markAsTouched();

      if ((control instanceof FormGroup || control instanceof FormArray) && control.controls) {
        this.markAllTouched(control);
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.dialogRef.close(this.model);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
