import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-champ-heure',
  template: /*html */`
  <app-time-input [formControl]="formControl" [picker]="true" [label]="to.label" [required]="to.required"></app-time-input>
  <span *ngIf="(formControl.dirty || formControl.touched) && formControl.errors?.required" class="mat-error">Obligatoire</span>
  `,
  styles: [
    `:host {
      display: block;
      margin-right: 10px;
    }
    .mat-error {
      display: block;
      height: 0;
      margin: 0.2em 0 0.3em;
      font-size: 90%;
    }
    `
  ]
})
export class ChampHeureComponent extends FieldType { }
