<div #formlyValidationMessage *ngIf="showError" class="mat-error">
  <formly-validation-message [field]="field">
  </formly-validation-message>
</div>

<div style="margin:10px 0;" *ngIf="!options.formState.disabled && !field.readOnly && !model">
  <button type="button" style="display:block; margin:auto;" mat-raised-button color="primary" (click)="onAddClick()"
    [disabled]="to.disabledSelectFamily">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
      <app-icon icon="hand-pointer" type="fa" size="20"></app-icon>
      <span>{{ 'Sélectionner ou ajouter '+to.itemLabel }}</span>
    </div>
  </button>
</div>

<div class="data-row row display-flex-row" *ngIf="model">

  <div class="data-container">
    <div *ngIf="field.title" [innerHTML]="field.title|safe:'html'"></div>
    <span *ngFor="let colDef of to.columns" [ngClass]="colDef.className">
      {{ getColValue(field,colDef.key) }}
    </span>
    <div *ngIf="field.formControl.invalid" class="item-alert">
      <mat-icon>warning</mat-icon>
      {{to.invalidItemLabel }}
    </div>
  </div>

  <div class="buttons-container">
    <ng-container *ngIf="!(options.formState.disabled || field.readOnly)">
      <button mat-icon-button type="button" [color]="primary" (click)="openEditDialog(field)"
        matTooltip="Voir / modifier">
        <mat-icon>edit</mat-icon>
      </button>
    </ng-container>
    <ng-container
      *ngIf="!(options.formState.disabled || field.readOnly) && field.model && isNew && !to.hideReplaceFamily">
      <button mat-icon-button type="button" color="accent" (click)="onAddClick()"
        [matTooltip]="'Sélectionner/Ajouter '+to.itemLabel">
        <mat-icon>find_replace</mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="options.formState.disabled || field.readOnly">
      <button mat-icon-button type="button" color="primary" (click)="openEditDialog(field)" matTooltip="Voir">
        <mat-icon>visibility</mat-icon>
      </button>
    </ng-container>
  </div>

</div>

<ng-template #suggestionDialog>
  <div class="suggest-dialog">
    <h1 mat-dialog-title>Sélectionner ou ajouter {{ to.itemLabel }}</h1>
    <div mat-dialog-content>

      <div *ngIf="showError" class="mat-error">
        <formly-validation-message [field]="field">
        </formly-validation-message>
      </div>

      <mat-radio-group [(ngModel)]="selection" fxLayout="column" fxLayoutGap="8px" class="radio-btn">
        <mat-radio-button *ngFor="let item of suggestedItems" [value]="item">
          <span *ngFor="let colDef of to.columns" [ngClass]="colDef.className">
            <span *ngIf="colDef.key !== 'dateNaissance'" style="margin-right: 3px;">{{item[colDef.key]}}</span>
            <span *ngIf="colDef.key === 'dateNaissance'">{{item[colDef.key] | date: 'dd/MM/yyyy' }}</span>
          </span>
        </mat-radio-button>
      </mat-radio-group>

      <button *ngIf="!field.selectOnly" mat-stroked-button color="primary" mat-dialog-close
        (click)="openEditDialog(null)" class="add-new-button">
        <mat-icon>add_circle</mat-icon>
        {{ 'Ajouter '+ to.itemLabel }}
      </button>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="warn" mat-dialog-close>
        <mat-icon>close</mat-icon> Annuler
      </button>
      <button mat-raised-button color="accent" mat-dialog-close (click)="validateSelection()" [disabled]="!selection">
        <mat-icon>check</mat-icon>
        Valider la sélection
      </button>
    </div>

  </div>

</ng-template>
