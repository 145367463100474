import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { ApiCrudService } from './api-crud.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { CookieConfig, GlobalConfig } from '@app/models/global-config';

const cookieDefault: NgcCookieConsentConfig = {
  cookie: { domain: 'localhost' },
  position: 'top-right',
  theme: 'classic',
  type: 'info',
  palette: {
    popup: {
      background: 'rgb(57,58,66)',
      text: '#ffffff',
      link: '#ffffff'
    },
    button: {
      background: '#FCFCF6',
      text: '#000000',
      border: 'transparent'
    }
  },
  content: {
    deny: 'Refuser',
    link: '',
    href: '',
    policy: 'Cookie Policy'
  }
};

@Injectable({ providedIn: 'root' })
export class GlobalConfigService extends ApiCrudService<GlobalConfig> {

  url = 'conf/global';

  configSubject: BehaviorSubject<GlobalConfig>;
  config$: Observable<GlobalConfig>;

  constructor(
    protected http: HttpClient
  ) {
    super();
    this.configSubject = new BehaviorSubject(null);
    this.config$ = this.configSubject.asObservable().pipe(filter(c => !!c)); // Ok here because we init with null ...
  }

  get settings() {
    return this.configSubject.value;
  }

  init() {
    return super.get().pipe(
      tap(config => {
        config = config || {};
        this.parseGlobalConfig(config);
        this.configSubject.next(config);
      })
    );
  }

  parseGlobalConfig(config: GlobalConfig) {
    config.email = config.email || {};

    // Set default settings for "cookie" (consent config)
    if (config.cookie) {
      config.cookie.text = config.cookie.text || 'Notre portail famille utilise des cookies pour vous garantir la meilleure expérience de navigation.';
      config.cookie.dismiss = config.cookie.dismiss || 'Compris';
      // config.cookie.domain = config.cookie.domain || window.location.host; // hacky but hey ! want this to show at every log / refresh ?
      config.cookie.domain = window.location.hostname; // hacky but hey ! want this to show at every log / refresh ?
    }

    if (config.cookie.domain !== window.location.hostname) {
      console.warn(
        `Cookie config domain (${config.cookie.domain}) is different from app URL (${window.location.hostname})`
        + ' => cookie consent will probably popup at every refresh ...'
      );
    }
  }

  getCookieConsentFromConfig(config: CookieConfig) {
    const consentConfig = cookieDefault;

    consentConfig.cookie.domain = config.domain;
    consentConfig.content.message = config.text;
    consentConfig.content.dismiss = config.dismiss;

    return consentConfig;
  }

  getPart<T>(part: keyof GlobalConfig): Observable<T> {
    return this.config$.pipe(
      map(conf => conf[part] as T)
    );
  }

  update(config: GlobalConfig) {
    this.configSubject.next(config);
    return super.update({ config });
  }

  updatePart(part: keyof GlobalConfig, partData: object | any, save = false) {
    const data = this.settings;
    data[part] = partData;

    this.configSubject.next(data);

    return save ? this.update(this.settings) : of(true);
  }
}
