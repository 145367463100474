import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AuthenticationService, PlatformService } from '@app/services';
import { Role } from '@app/models/user';
import { PermissionService } from '@app/services/permission.service';

const storageUsernameKey = 'portail-remember-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Input() homeCard = false;

  error: string;
  loginForm: FormGroup;
  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public authService: AuthenticationService,
    public permService: PermissionService,
    public platformService: PlatformService,
  ) {
    this.createForm();
  }

  ngOnInit() {
    const inputEmail = this.route.snapshot.paramMap.get('email') || localStorage.getItem(storageUsernameKey);

    if (inputEmail) {
      this.loginForm.get('username').setValue(inputEmail);
    }
  }

  login() {
    this.isLoading = true;
    this.authService.login(this.loginForm.value)
      .pipe(finalize(() => {
        this.loginForm.markAsPristine();
        this.isLoading = false;
      }))
      .subscribe(user => {

        if (this.loginForm.get('remember').value) {
          localStorage.setItem(storageUsernameKey, this.loginForm.value?.username);
        }

        this.route.queryParams.subscribe(params => {
          // Redirect to the originally wanted route, if any
          // Else if user role is defined, redirect to right home page
          // By default, redirect to home
          const target = params.redirect ? params.redirect : (user.role ? (user.role === Role.Admin ? '/admin' : '/account') : '/');
          this.router.navigate([target], { replaceUrl: true });
        });
      }, error => {
        this.error = error;
      });
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true
    });
  }

}
