import { Injectable } from '@angular/core';
import { Child } from '@app/models/child';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, tap } from 'rxjs/operators';
import { ChildAdapter } from './adapter/child-adapter.service';
import { ApiCrudService } from './api-crud.service';
import { FamilyService } from './family.service';
import { of, Observable } from 'rxjs';
import { Family } from '@app/models/family';

@Injectable({
  providedIn: 'root'
})
export class ChildService extends ApiCrudService<Child> {

  url = 'enfants';
  formUrl = 'enfants/#idEnfant#/form/#formPart#';

  constructor(
    protected http: HttpClient,
    private adapter: ChildAdapter,
    private familyService: FamilyService
  ) {
    super();
  }

  getAll() {
    // Actually forbidden ? (or admin only ..)
    return super.getAll().pipe(
      map((data: []) => data.map(item => this.adapter.adapt(item)))
    );
  }

  getCurrentFamilyChildren(): Observable<Child[]> {
    if (this.familyService.currentFamily) {
      return this.getFamilyChildren(this.familyService.currentFamily);
    }
    return of(null);
  }

  getFamilyChildren(family: Family | number): Observable<Child[]> {
    const id = typeof family === 'object' ? family.id : family;

    return this.http.get<Child[]>(`familles/${id}/enfants`).pipe(
      map((data: []) => data.map(item => this.adapter.adapt(item)))
    );
  }

  getFamilyChild(idFamily: number, idChild: number) {
    return this.getFamilyChildren(idFamily).pipe(
      map(children => children.find(c => c.id === idChild))
    );
  }

  create(data: Child | any) {
    return this.http.post(this.familyService.getCurrentFamilyDependantUrl(this.url), data, this.httpOptions);
  }

  update(data: Child | any, id?: number, part?: string) {
    part = part ? part : '';
    const url = this.url + (id ? '/' + id : '') + '/' + part ;

    return this.http.put(url, data, this.httpOptions);
  }

  getFormData(id, part) {
    if (!id){
      return of({modeCreation:true})
    }
    
    part = part ? part : '';

    const url = this.formUrl.replace('#idEnfant#', id).replace('#formPart#', part);
    return this.http.get(url);
  }
}
