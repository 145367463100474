import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AnneeScolaire, CogitoEcole, CogitoPreinscription, CogitoSecteurScolaire, ConfigCogito, MotifDerogation, NiveauScolaire } from '@app/models/cogito';
import { UsagerChild } from '@app/models/consumer';
import { FamilyService, PlatformService, SnackbarService } from '@app/services';
import { CogitoService } from '@app/services/cogito.service';
import { HeaderService } from '@app/services/header.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-edit-cogito-preinscription',
  templateUrl: './edit-cogito-preinscription.component.html',
  styleUrls: ['./edit-cogito-preinscription.component.scss']
})
export class EditCogitoPreinscriptionComponent implements OnInit {

  title = 'Création d\'une pré-inscription scolaire';
  idFromUrl: number;
  config: ConfigCogito;
  derogationMandatory: boolean;
  options: { childList: UsagerChild[], secteursScolaireList: CogitoSecteurScolaire[], secteursScolaireListBydefault: CogitoSecteurScolaire[] };
  isEdit: boolean = false;
  saving: boolean = false;
  preinscription: CogitoPreinscription;
  selectedChild: UsagerChild;
  selectedAnneeScolaire: AnneeScolaire;
  selectedNiveauScolaire: NiveauScolaire;
  selectedSecteurScolaire: CogitoSecteurScolaire;
  listNiveauxScolairesAvailable: NiveauScolaire[];
  ageEnfantCalculateByAnneeScolaire: { years: number, months: number };
  dateToCalculate: Date;
  listEcolesAvailable: CogitoEcole[];
  listSecteursScolaireAvailable: CogitoSecteurScolaire[];
  motifDerogationScolaire: MotifDerogation[];
  demandeDerogation: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private familyService: FamilyService,
    private cogitoService: CogitoService,
    public platformService: PlatformService,
    private translate: TranslateService,
    private snackbar: SnackbarService,
    private headerService: HeaderService
  ) { }

  ngOnInit(): void {
    this.idFromUrl = this.route.snapshot.paramMap.has('id') ? +this.route.snapshot.paramMap.get('id') : null;

    if (this.idFromUrl) {
      this.title = "Modification d'une pré-inscription scolaire";
    }

    this.headerService.setCurrentPageTitle(this.title);

    this.loadData().subscribe(_ => {
      if (this.isEdit) {

        if (this.preinscription.derogationExist) {
          this.demandeDerogation = true;
        }

        this.dateToCalculate = this.setAnneeScolaireToCalculate(this.preinscription.anneeScolaire.dateDebut);
        this.ageEnfantCalculateByAnneeScolaire = this.setAgeEnfantByAnneeScolaire(this.dateToCalculate, this.preinscription.dateNaissance);

        this.listSecteursScolaireAvailable = this.globalfilterSecteursScolaires(this.preinscription.cycleScolaireName)
        this.listEcolesAvailable = this.globalFilterEcoles(this.preinscription.secteurScolaire, this.preinscription.cycleScolaireName)

        this.filtreNiveauScolaireByAgeEnfant(this.ageEnfantCalculateByAnneeScolaire.years);
        this.setAgeEnfantDisplayByAnneeScolaire();

        if (!this.config.enabledSelectNiveauScolaire) {
          this.listNiveauxScolairesAvailable = [...this.config.listNiveauxScolaires];
        }
      } else {
        this.preinscription = {};
        if (!this.config.enabledSelectAnneeScolaire) {

          this.preinscription.anneeScolaire = this.config.anneeScolaireByDefault;
          this.config.listAnneesScolaires = [];
          this.config.listAnneesScolaires.push(this.config.anneeScolaireByDefault)
          this.dateToCalculate = this.setAnneeScolaireToCalculate(this.preinscription.anneeScolaire.dateDebut)

          if (this.options.childList) {
            this.setAgeEnfantDisplayByAnneeScolaire();
          }
        }
      }
    })
  }

  loadData() {
    return this.familyService.currentFamilyReadyOnce$.pipe(
      switchMap(family => {
        const loaders = [];

        loaders.push(this.cogitoService.getConfigForUser().pipe(tap((c: any) => {
          this.config = c.config;
          this.derogationMandatory = c.derogationObligatoire;
        })));

        if (this.idFromUrl) {
          this.isEdit = true;

          // load edit preinscription
          loaders.push(this.cogitoService.getPreinscription(family, this.idFromUrl).pipe(tap(p => {
            this.preinscription = p;
          })));
        }

        loaders.push(this.cogitoService.getOptions(family).pipe(tap(o => {
          this.options = o;
        })));


        return forkJoin(loaders);
      })
    );
  }

  compareAnneeFn(annee1: AnneeScolaire, annee2: AnneeScolaire) {
    return annee1 && annee2 ? annee1.idAnneeScolaire === annee2.idAnneeScolaire : annee1 === annee2;
  }

  filtreSecteursScolairesByCycleScolaire(cycleScolaire: string) {
    if (this.derogationMandatory && !this.demandeDerogation) {
      let secteurScolaireList = this.options.secteursScolaireListBydefault.filter(secteur => {
        if (secteur.cyclesScolaires) {
          let tabCyclesScolaires = secteur.cyclesScolaires.split(',');
          return tabCyclesScolaires.includes(cycleScolaire);
        }
      })

      if (secteurScolaireList.length) {
        return secteurScolaireList;
      } else {
        return this.options.secteursScolaireListBydefault.filter(secteur => secteur.estExterieur);
      }

    } else {
      return this.options.secteursScolaireList.filter(secteur => {
        let tabCyclesScolaires = secteur.cyclesScolaires.split(',');
        return tabCyclesScolaires.includes(cycleScolaire);
      });
    }
  }

  filtreEcolesByCycleScolaire(cycleScolaire: string, idSecteurScolaire: number) {
    let tabSecteurScolaire = (this.derogationMandatory && !this.demandeDerogation) ? this.options.secteursScolaireListBydefault : this.options.secteursScolaireList;
    return tabSecteurScolaire
      .find(secteur => secteur.idSecteurScolaire === idSecteurScolaire)?.tabEcole
      .filter(ecole => ecole.tabCyclesScolaire.some(cycle => cycle.libelle === cycleScolaire));
  }

  filtreEcolesByClassesOuvertes(listEcole: CogitoEcole[]) {
    let listEcoleFiltered = listEcole.filter(ecole => {
      return ecole.tabClasse
        .some(classe => {
          return classe.tabNiveauScolaire.some(nivScolaire => nivScolaire.idNiveauScolaire === this.preinscription.niveauScolaire) &&
            classe.tabAnneeScolaire.some(anneeScolaire => anneeScolaire.idAnneeScolaire === this.preinscription.anneeScolaire.idAnneeScolaire)
        })
    })
    return listEcoleFiltered;
  }

  filtreSecteurScolaireByClasseOuverte(listSecteurScolaire: CogitoSecteurScolaire[]): CogitoSecteurScolaire[] {
    return listSecteurScolaire.filter(secteurScolaire => this.filtreEcolesByClassesOuvertes(secteurScolaire.tabEcole).length);
  }

  globalFilterEcoles(idSecteurScolaire: number, cycleScolaire: string): CogitoEcole[] {
    let listEcolesByCycleScolaire = this.filtreEcolesByCycleScolaire(cycleScolaire, idSecteurScolaire);
    return this.filtreEcolesByClassesOuvertes(listEcolesByCycleScolaire || []);
  }

  globalfilterSecteursScolaires(cycleScolaire: string): CogitoSecteurScolaire[] {
    let listSecteurScolaireByCycleScolaire = this.filtreSecteursScolairesByCycleScolaire(cycleScolaire);

    if (this.derogationMandatory && !this.demandeDerogation) {
      return listSecteurScolaireByCycleScolaire;
    } else {
      return this.filtreSecteurScolaireByClasseOuverte(listSecteurScolaireByCycleScolaire);
    }
  }

  childChanged(value: number) {
    this.selectedChild = this.options.childList.find(child => child.id === value);
    this.preinscription.idEnfant = this.selectedChild.id;
    if (this.selectedAnneeScolaire || this.preinscription.anneeScolaire) {

      this.selectedAnneeScolaire = this.selectedAnneeScolaire ? this.selectedAnneeScolaire : this.preinscription.anneeScolaire;
      this.ageEnfantCalculateByAnneeScolaire = this.setAgeEnfantByAnneeScolaire(this.dateToCalculate, this.selectedChild.dateNaissance);
      this.filtreNiveauScolaireByAgeEnfant(this.ageEnfantCalculateByAnneeScolaire.years);

      if (!this.config.enabledSelectNiveauScolaire) {
        this.preinscription.niveauScolaire = this.listNiveauxScolairesAvailable.length ? this.listNiveauxScolairesAvailable[0].idNiveauScolaire : 0;
        this.niveauScolaireChanged(this.preinscription?.niveauScolaire)
      }

      if (!this.listNiveauxScolairesAvailable.find(niveau => niveau.idNiveauScolaire === this.preinscription.niveauScolaire)) {
        this.preinscription.niveauScolaire = null;
        this.niveauScolaireChanged(null);
      }
    }
  }

  anneeScolaireChanged(value: AnneeScolaire) {
    this.selectedAnneeScolaire = this.config.listAnneesScolaires.find(annee => annee.idAnneeScolaire === value.idAnneeScolaire);
    this.preinscription.anneeScolaire = this.selectedAnneeScolaire;

    this.dateToCalculate = this.setAnneeScolaireToCalculate(this.selectedAnneeScolaire.dateDebut);
    this.ageEnfantCalculateByAnneeScolaire = this.setAgeEnfantByAnneeScolaire(this.dateToCalculate, this.selectedChild.dateNaissance);
    this.filtreNiveauScolaireByAgeEnfant(this.ageEnfantCalculateByAnneeScolaire.years);

    if (!this.listNiveauxScolairesAvailable.find(niveau => niveau.idNiveauScolaire === this.preinscription.niveauScolaire)) {
      this.preinscription.niveauScolaire = null;
      this.niveauScolaireChanged(null);
    }

    if (!this.config.enabledSelectNiveauScolaire) {
      this.preinscription.niveauScolaire = this.listNiveauxScolairesAvailable[0].idNiveauScolaire
      this.niveauScolaireChanged(this.preinscription.niveauScolaire)
    }

    if (this.options.childList) {
      this.setAgeEnfantDisplayByAnneeScolaire();
    }

  }

  niveauScolaireChanged(value: Number) {
    this.selectedNiveauScolaire = this.config.listNiveauxScolaires.find(niveau => niveau.idNiveauScolaire === value);
    this.listSecteursScolaireAvailable = this.globalfilterSecteursScolaires(this.selectedNiveauScolaire?.cycleScolaire);

    if (this.selectedNiveauScolaire) {
      this.preinscription.secteurScolaire = (this.derogationMandatory && !this.demandeDerogation) ?
        this.listSecteursScolaireAvailable[0]?.idSecteurScolaire : null;
    }
    this.secteurScolaireChanged(null);
  }

  secteurScolaireChanged(value: number) {
    this.preinscription.ecole = null;

    if (this.derogationMandatory && !this.demandeDerogation) {
      value = this.preinscription.secteurScolaire;
    }

    this.listEcolesAvailable = this.globalFilterEcoles(value, this.selectedNiveauScolaire?.cycleScolaire || this.preinscription.cycleScolaireName);
  }

  setAnneeScolaireToCalculate(dateDebut): Date {
    //au 31 décembre de l'année de début
    let anneeScolaireDebut = moment(dateDebut).year();
    return new Date(anneeScolaireDebut, 11, 31);
  }

  filtreNiveauScolaireByAgeEnfant(ageEnfant: number) {
    let tabAgeAvailable;

    this.listNiveauxScolairesAvailable = this.config.listNiveauxScolaires.filter(niveau => {

      if (this.config.enabledSelectNiveauScolaire) {

        if (this.config.limitSelectNiveauScolaire === "plus ou moins 2 ans") {
          tabAgeAvailable = this.setTabAgeAvailabled(2, ageEnfant);
          return tabAgeAvailable.includes(niveau.ageEnfant);
        }
        if (this.config.limitSelectNiveauScolaire === "plus ou moins 1 an") {
          tabAgeAvailable = this.setTabAgeAvailabled(1, ageEnfant);
          return tabAgeAvailable.includes(niveau.ageEnfant);
        }
        if (this.config.limitSelectNiveauScolaire === "NoLimit" || this.config.limitSelectNiveauScolaire === "") {
          return this.config.listNiveauxScolaires;
        }
      } else return niveau.ageEnfant === ageEnfant;
    });
  }


  setTabAgeAvailabled(limitAgeEnfant: number, ageChild: number): number[] {

    let tabAgeNiveauAvailable = []
    for (let index = 0; index <= limitAgeEnfant; index++) {
      tabAgeNiveauAvailable.push(ageChild++);
    }
    let minAge = Math.min(...tabAgeNiveauAvailable) - 1;
    for (let index = 0; index < limitAgeEnfant; index++) {
      tabAgeNiveauAvailable.push(minAge--);
    }

    return tabAgeNiveauAvailable;

  }

  setAgeEnfantDisplayByAnneeScolaire() {
    if (this.options && !this.isEdit) {
      this.options.childList.forEach(child => {
        child.age = this.setAgeEnfantByAnneeScolaire(this.dateToCalculate, child.dateNaissance);
      })
    } else {
      this.preinscription.yearsMonthAgeEnfant = this.setAgeEnfantByAnneeScolaire(this.dateToCalculate, this.preinscription.dateNaissance);
    }
  }

  setAgeEnfantByAnneeScolaire(anneeScolaire: string | Date, dateNaissanceEnfant: string | Date): { years: number, months: number } {
    let anneeScolaireMomentFormat = moment(anneeScolaire);
    let dateNaissanceEnfantMomentFormat = moment(dateNaissanceEnfant);

    let years = anneeScolaireMomentFormat.diff(dateNaissanceEnfantMomentFormat, 'years');
    dateNaissanceEnfantMomentFormat.add(years, 'years');

    let months = anneeScolaireMomentFormat.diff(dateNaissanceEnfantMomentFormat, 'months');
    dateNaissanceEnfantMomentFormat.add(months, 'months');

    return { years, months };
  }

  setDisplayDateToCalculate(dateToCalculate: Date): string {
    return moment(dateToCalculate).format('DD/MM/YYYY');
  }

  onClickDemandeDerogation() {
    this.demandeDerogation = !this.demandeDerogation;
    this.listSecteursScolaireAvailable = this.globalfilterSecteursScolaires(this.selectedNiveauScolaire?.cycleScolaire);

    if (!this.derogationMandatory) {
      this.preinscription.secteurScolaire = null;
    }

    if (!this.demandeDerogation) {
      this.preinscription.idMotifDerogation = null;
      this.secteurScolaireChanged(null);
      this.preinscription.secteurScolaire = this.listSecteursScolaireAvailable[0].idSecteurScolaire;
    }
  }

  onClickValidatePreInscription() {
    let preinscription = this.mapToSave(this.preinscription);
    const family = this.familyService.currentFamily;

    this.saving = true;

    this.cogitoService.savePreinscription(preinscription, family?.id).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      if (!res.errors) {
        this.snackbar.info('info.success.saving');
        this.router.navigate(['/account/cogito-preinscriptions']);
      } else {
        res.errors.forEach((err: { message: string }) => {
          let message = err.message as string;

          if (message === 'cogito.error.doublon_exist') {
            message = this.translate.instant(message);
            this.snackbar.error(message);
          }
        })
      }
    });
  }

  onClickAnnuler() {
    history.back();
  }

  mapToSave(data: CogitoPreinscription): any {

    let dataToSave: any = {};
    dataToSave._idEnfant = data.idEnfant;
    dataToSave._idAnneeScolaire = data.anneeScolaire.idAnneeScolaire;
    dataToSave._idNiveauScolaire = data.niveauScolaire;
    dataToSave._idSecteurScolaire = data.secteurScolaire;
    dataToSave._idEcole = data.ecole;
    dataToSave.idPreInscriptionScolaire = data.idPreInscriptionScolaire;
    dataToSave.idMotifDerogation = data.idMotifDerogation;
    dataToSave.derogationExist = this.demandeDerogation ? true : false;

    return dataToSave;
  }
}
