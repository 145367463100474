import { Injectable } from '@angular/core';
import {
  Periode, PeriodeRubrique, PeriodeActivite, ReservationLimiteDate,
  ActiviteDay, HoursData, PeriodePlage, PeriodeDay, RubriqueChain
} from '@app/models/periode';
import { ReservationPresence, PresenceState, BaseReservationPresence, PresenceError } from '@app/models/reservation';
import { EventInput } from '@fullcalendar/core';
import moment from 'moment';
import { mostReadable } from '@ctrl/tinycolor';
import { ThemeService } from './theme.service';
import { RepeatConfig } from '@app/components/_user/reservation/planning/presence-repeat/presence-repeat.component';
import { PlanningData } from '@app/components/_user/reservation/planning/planning-data';

export const DATE_FORMAT = 'Y-MM-DD';
export const DATETIME_FORMAT = 'Y-MM-DDTHH:mm:ss.SSS';

export interface ActivityWithDay extends PeriodeActivite, ActiviteDay { }

const textColors = [ThemeService.COMMON_COLORS.lightPrimaryText, ThemeService.COMMON_COLORS.darkPrimaryText];

@Injectable({
  providedIn: 'root'
})
export class PlanningService {

  getMergedOpenDays(rubrique: PeriodeRubrique, periode: Periode) {
    let openDays = periode.weekdays;

    // Double check for open days : rubrique may have specific days BUT these must fit Periode ones
    if (rubrique.openDays) {
      const rubDays = rubrique.openDays.map(i => i);
      openDays = openDays.filter(x => rubDays.includes(x));
    }

    return openDays;
  }

  // Get recurrent presences from one
  getRecurrentPresences(presences: ReservationPresence[], presence: BaseReservationPresence, periode: Periode) {
    const rubrique = periode.rubriques.find(rub => rub.id === presence.rubrique);

    if (!rubrique) {
      console.warn('Présence ' + presence.id + ' erreur : La rubrique '
        + presence.rubrique + ' est introuvable sur la période ' + periode.id);
      return [];
    }

    const linkedDates = this.getLinkedDates(presence.date, rubrique, periode);

    const linkedPresences = linkedDates.map(da => presences.find(pr => {
      // Finding presences of same group from this is too random, we should have stored it server side
      // At least with "Array.find" we get what we want : one presence per date
      return pr.date === da && pr.rubrique === rubrique.id && this.isSimilarState(presence, pr);
    })).filter(pr => !!pr);

    // For "camps" mode (grouped) activities presences
    if (presence.activities) {
      const dayActs = this.getActivitiesWithDay(periode.activites);
      for (const prAct of presence.activities) {
        const id = typeof prAct === 'object' ? prAct.id : prAct; // because of DetailedPresence ...
        const dayAct = dayActs.find(ad => ad.id === id && ad.date === presence.date);
        if (dayAct && dayAct.group) {
          const linkedActs = dayActs.filter(ad => ad.id === dayAct.id && ad.group === dayAct.group);
          // Find presences containing grouped activity
          // @TODO: add filter on same periode ! (else we can get presences from 2 years before ...)
          presences.filter(pr =>
            pr.activities &&
            pr.activities.find(otherPrAct => linkedActs.find(ad => ad.id === otherPrAct)) &&
            !linkedPresences.find(ex => this.isSamePresence(pr, ex))
          ).forEach(pr => linkedPresences.push(pr));
        }
      }
    }

    return linkedPresences;
  }

  isSimilarState(pr1: BaseReservationPresence, pr2: BaseReservationPresence) {
    return !pr1.replacedBy === !pr2.replacedBy && (pr1.askCancel ? pr2.askCancel : pr1.state === pr2.state);
  }

  // Should make it easier to find corresponding presences for group cancel etc
  getLinkedDates(date: Date | string, rubrique: PeriodeRubrique, periode: Periode): string[] {

    let repeatMoment = moment(date);
    const subMode = rubrique.modeSelection || periode.modeSelection;
    const openDays = this.getMergedOpenDays(rubrique, periode);

    if (subMode === 'free') {
      return [repeatMoment.format(DATE_FORMAT)];
    }

    const result = [];

    if (subMode === 'day') {
      const baseDay = repeatMoment.isoWeekday();
      repeatMoment = moment(periode.startDate);

      if (repeatMoment.isoWeekday() > baseDay) {
        repeatMoment.add(1, 'week');
      }

      repeatMoment.isoWeekday(baseDay);

      while (repeatMoment.isBefore(periode.endDate)) {
        result.push(repeatMoment.format(DATE_FORMAT));
        repeatMoment.add(1, 'week');
      }
    } else if (subMode === 'week') {
      openDays.forEach(i => {
        repeatMoment.isoWeekday(i);
        result.push(repeatMoment.format(DATE_FORMAT));
      });
    } else if (subMode === 'month') {
      const baseMonth = repeatMoment.month();
      repeatMoment.date(1);
      while (repeatMoment.month() === baseMonth) {
        if (openDays.includes(repeatMoment.isoWeekday())) {
          result.push(repeatMoment.format(DATE_FORMAT));
        }
        repeatMoment.add(1, 'day');
      }
    } else if (subMode === 'period') {
      repeatMoment = moment(periode.startDate);
      while (repeatMoment.isSameOrBefore(periode.endDate)) {
        if (openDays.includes(repeatMoment.isoWeekday())) {
          result.push(repeatMoment.format(DATE_FORMAT));
        }
        repeatMoment.add(1, 'day');
      }
    } else {
      // By default, just return current date
      return [repeatMoment.format(DATE_FORMAT)];
    }

    return result;
  }

  getRepeatDates(repeatConfig: RepeatConfig) {
    const start = moment(repeatConfig.startDate);
    const end = moment(repeatConfig.endDate).format(DATE_FORMAT);

    const result = [];
    while (start.format(DATE_FORMAT) <= end) {
      if (repeatConfig.weekDays.includes(start.isoWeekday())) {
        result.push(start.format(DATE_FORMAT));
      }

      // if we have a "week step" and we're on last day of the week
      if (repeatConfig.weekStep > 1 && start.format(DATE_FORMAT) === start.clone().endOf('week').format(DATE_FORMAT)) {
        start.add(((repeatConfig.weekStep - 1) * 7) + 1, 'day');
      } else {
        start.add(1, 'd');
      }
    }

    return result;
  }

  getMultipleDatesLinked(dates: string[], rubrique: PeriodeRubrique, periode: Periode) {
    const allDates = new Set<string>(); // Use Set to automatically filter double values

    dates.forEach(oneDate => this.getLinkedDates(oneDate, rubrique, periode).forEach(linkDate => allDates.add(linkDate)));

    return Array.from(allDates);
  }

  computeFirstDate(now: moment.Moment, limite: ReservationLimiteDate, feries: string[], periode: Periode) {
    // If no limit consider we can access from today
    if (!limite || limite.type === 'fixed-date') {
      return now.format(DATE_FORMAT);
    }

    const testDate = now.clone();

    // Theorically the limit date config should not be above 30 days ahead ...
    for (let i = 1; i < 90; i++) {
      const limitDate = this.getLimitDate(limite, testDate, feries, periode);

      if (now.isBefore(limitDate)) {
        return testDate.format(DATE_FORMAT);
      }

      testDate.add(1, 'd');
    }

    return null;
  }

  getLimitDate(limite: ReservationLimiteDate, date: Date | moment.Moment, feries: string[], periode: Periode) {
    date = moment(date);

    const openableDaysExclude = [7];
    const openDaysExclude = [6, 7];

    switch (limite.type) {
      case 'fixed-date':
        date = moment(limite.fixedDate);
        break;
      case 'variable':
        let daysCount = limite.number;
        let dateStr = date.format(DATE_FORMAT);
        let weekDay: number;

        // if we went before first saisie date, pointless, just return the date before
        while (daysCount > 0) {
          date.subtract(1, 'd');
          dateStr = date.format(DATE_FORMAT);
          weekDay = date.isoWeekday();

          // handle the case where we are just before the periode start and want a 10 days delay, we can no longer count in PeriodeDays
          if (limite.unit === 'periodeDay' && (dateStr < periode.saisieDebut || dateStr < periode.startDate)) {
            date.subtract(daysCount, 'd');
            break;
          }

          if (limite.unit === 'day' ||
            (limite.unit === 'openDay' && !(openDaysExclude.includes(weekDay) || feries.includes(dateStr))) ||
            (limite.unit === 'openableDay' && !(openableDaysExclude.includes(weekDay) || feries.includes(dateStr))) ||
            (limite.unit === 'periodeDay' && periode.days.some(d => d.date === dateStr))) {
            daysCount--;
          }
        }
        break;
      case 'week-fixed':
        date.week(date.week() - 1);
        date.day(limite.weekDay);
        break;
      case 'month-fixed':
        date.subtract(1, 'month');
        date.date(limite.monthDate);
        break;
      case 'month-param':
        if (limite.monthDayOrder === 'last') {
          date.date(1).subtract(1, 'd');
          while (date.day() !== limite.monthDay) {
            date.subtract(1, 'd');
          }
        } else {
          date.month(date.month() - 1);
          date.date(1);
          while (date.day() !== limite.monthDay) {
            date.add(1, 'd');
          }
        }
        break;
    }

    if (limite.time) {
      const timeParts = limite.time.split(':').map(p => parseInt(p, 10));
      date.hour(timeParts[0]).minute(timeParts[1]).add(limite.toleranceMinutes, 'm');
    }

    return date;
  }

  hasConflict(presence: ReservationPresence, presences: ReservationPresence[]) {
    return presences.some(pr => this.isConflict(pr, presence));
  }

  getConflicts(presence: ReservationPresence, presences: ReservationPresence[]) {
    return presences.filter(pr => this.isConflict(pr, presence));
  }

  isConflict(pr1: ReservationPresence, pr2: ReservationPresence) {
    // Might also check for activities hours ?
    return pr1.date === pr2.date && this.presenceHoursOverlap(pr1, pr2) && !this.isSamePresence(pr1, pr2);
  }

  isSamePresence(pr1: ReservationPresence, pr2: ReservationPresence) {
    return pr1.id === pr2.id && pr1.tmpId === pr2.tmpId;
  }

  presenceHoursOverlap(pr1: ReservationPresence, pr2: ReservationPresence) {
    return this.checkTimeOverlap({ start: pr1.startTime, end: pr1.endTime }, { start: pr2.startTime, end: pr2.endTime });
  }

  checkTimeOverlap(hours1: HoursData, hours2: HoursData) {
    return (
      (hours1.start >= hours2.start && hours1.start < hours2.end) ||
      (hours1.start <= hours2.start && hours1.end > hours2.start) ||
      (hours1.end <= hours2.end && hours1.end > hours2.start)
    );
  }

  checkTimesOverlap(hours1: HoursData[], hours2: HoursData[]) {
    return hours1.some(hr1 => hr1.start && hr1.end && hours2.some(hr2 => hr2.start && hr2.end && this.checkTimeOverlap(hr1, hr2)));
  }

  getPresenceHours(presence: ReservationPresence): HoursData[] {
    return [{ start: presence.startTime, end: presence.endTime }, { start: presence.startTime2, end: presence.endTime2 }];
  }

  filterPresences(presences: ReservationPresence[], canceled = false, replaced = false) {
    return presences.filter(pr => (this.isCanceledOrDenied(pr) === canceled) && (!pr.replacedBy === !replaced));
  }

  isCanceledOrDenied(presence: BaseReservationPresence, orCanceling = true) {
    return presence.state < 0 || (orCanceling && !!presence.askCancel);
  }

  filterRubriquesForDay(rubriques: PeriodeRubrique[], weekDay: number) {
    return rubriques.filter(r => !r.openDays || r.openDays.includes(weekDay));
  }

  getActivitiesWithDay(activities: PeriodeActivite[]): ActivityWithDay[] {
    const result = [];

    activities.forEach(act => act.days.forEach(ad => result.push({ ...act, ...ad })));

    return result;
  }

  getActivityWithDay(activity: PeriodeActivite, date: string): ActivityWithDay {
    if (activity) {
      // Search an activite suiting current date
      const ad = activity.days.find(d => d.date === date);

      if (ad) {
        // Return merged activite + day data
        return { ...activity, ...ad };
      }
    }

    return null;
  }

  getDayActivities(activities: PeriodeActivite[], date: string): ActivityWithDay[] {
    return activities.map(act => this.getActivityWithDay(act, date)).filter(act => !!act);
  }

  getGroupedActivities(activity: ActivityWithDay, activities: ActivityWithDay[]): ActivityWithDay[] {
    return activities.filter(act => act.id === activity.id && act.group && act.group === activity.group);
  }

  // Small Activity helper @TODO: replace with user (admin) manual input
  getActivityPreview(act: ActivityWithDay, previewTextLimit = 200) {
    const descriptionEl = document.createElement('div');
    descriptionEl.innerHTML = act.description;

    const image = descriptionEl.querySelector('img');
    const content = descriptionEl.innerText.trim();

    return {
      image: image ? image.getAttribute('src') : null,
      text: content && content.length > 200 ? content.slice(0, previewTextLimit) + '...' : content
    };
  }

  getRubriqueChainsPart(chains: number[][], search: number, part: 'before' | 'after' = 'before') {
    return (chains || []).filter(chain => chain.includes(search)).map(chain => this.getRubriqueChainPart(chain, search, part));
  }

  getRubriqueChainPart(chain: number[], ref: number, part: 'before' | 'after' = 'before') {
    return part === 'before' ? chain.slice(0, chain.indexOf(ref)) : chain.slice(chain.indexOf(ref) + 1);
  }

  getChainItems(chain: number[], rubriques: PeriodeRubrique[], presences?: ReservationPresence[]) {
    const found = [];

    for (const ch of chain) {
      const rub = rubriques.find(r => r.id === ch);

      if (rub) {
        found.push(rub);
        continue;
      }

      const prez = presences?.find(pr => pr.rubrique === ch);

      if (prez) {
        found.push(prez);
        continue;
      }
    }

    return found;
  }

  isChainCompliant(chain: number[], ids: number[]) {
    return !chain.find(one => !ids.includes(one));
  }

  hasChainCompliant(chains: number[][], ids: number[]) {
    return chains.find(chain => this.isChainCompliant(chain, ids));
  }

  getRubriqueRooms(rubrique: PeriodeRubrique, placesDispos: { plage: number, places: number }[]) {
    const rubriquePlages = rubrique.plages.map(pl => pl.id);

    // Get minimum places among time slots that Rubrique requires (if it's on two slots which one is full, then it's full)
    return Math.min(...placesDispos.filter(d => rubriquePlages.includes(d.plage)).map(d => d.places));
  }

  getDependantPresences(presence: ReservationPresence, presences: ReservationPresence[], chains: RubriqueChain[], acceptCanceled = false) {
    // Get all Rubriques that appear after this one
    const concernedChains = this.getRubriqueChainsPart(chains, presence.rubrique, 'after');

    const dependantPresences = [];

    for (const chain of concernedChains) {
      for (const rub of chain) {
        let depPr = presences.find(pr => pr.rubrique === rub && !pr.replacedBy && !this.isCanceledOrDenied(pr));

        if (!depPr && acceptCanceled) {
          depPr = presences.find(pr => pr.rubrique === rub && !pr.replacedBy && pr.askCancel && !pr.id);
        }

        if (depPr && !dependantPresences.some(ex => this.isSamePresence(ex, depPr))) {
          dependantPresences.push(depPr);
        }
      }
    }

    return dependantPresences;
  }

  getRequiredPresences(presence: ReservationPresence, presences: ReservationPresence[], chains: RubriqueChain[], acceptCanceled = false) {
    const concernedChains = this.getRubriqueChainsPart(chains, presence.rubrique, 'before').filter(c => c?.length);

    if (!concernedChains.length) {
      return [];
    }

    for (const chain of concernedChains) {
      const requiredPresences = chain.map(r => {
        let reqPr = presences.find(pr => pr.rubrique === r && !pr.replacedBy && !this.isCanceledOrDenied(pr));

        if (!reqPr && acceptCanceled) {
          reqPr = presences.find(pr => pr.rubrique === r && !pr.replacedBy && pr.askCancel && !pr.id);
        }

        // still can be null
        return reqPr;
      }).filter(pr => !!pr);

      if (requiredPresences.length === chain.length) {
        return requiredPresences;
      }
    }

    return false;
  }

  addActivityToPresence(presence: ReservationPresence, activity: ActivityWithDay) {
    const idActivity = typeof activity === 'object' ? activity.id : activity;

    if (!presence.activities) {
      presence.activities = [];
    } else if (activity.rubrique && activity.startTime) {
      console.warn('Error : cannot add a "youth mode" activity to a Presence with other activities');
      return;
    }

    if (!presence.activities.includes(idActivity)) {
      presence.activities.push(idActivity);
    }

    // Adapt Presence hours according to Activity ones
    if (activity.startTime && activity.endTime) {
      if (activity.startTime < activity.startTime) {
        presence.startTime = activity.startTime;
      }

      if (presence.endTime2) {
        presence.endTime2 = activity.endTime > presence.endTime2 ? activity.endTime : presence.endTime2;
      } else {
        presence.endTime = activity.endTime > presence.endTime ? activity.endTime : presence.endTime;
      }
    }
  }

  // Used in Planning & SelectDialog (careful if edit ...)
  // @NB: proposal for improvement : return more info ("chain" with missing rubriques, "conflict" with conflicting presences ...)
  checkPresenceError(
    presence: ReservationPresence,
    checkPresences: ReservationPresence[],
    periode: Periode,
    ignoreCapacity = false
  ): PresenceError {
    if (!ignoreCapacity && periode.liveCapacity && !this.checkPresenceHasRoom(presence, periode)) {
      return 'full';
    }

    // Check chain compliant
    // Chains where the Rubrique appears (before = required rubriques)
    const requiredChains = this.getRubriqueChainsPart(periode.rubriqueChains, presence.rubrique, 'before');

    if (requiredChains.length) {
      const sameDateRubriques = checkPresences.filter(pr => pr.date === presence.date).map(pr => pr.rubrique);
      if (!this.hasChainCompliant(requiredChains, sameDateRubriques)) {
        return 'chain';
      }
    }

    // Finally, check for conflict
    // @NB: there's a lack here : check for conflict among activities hours.
    // Though, including this check would require PlanningData (to retrieve each Presence activities, from Periode),
    // some more treatment, probably not always needed ...
    if (this.hasConflict(presence, checkPresences)) {
      return 'conflict';
    }

    return null;
  }

  setRecurrencyGroups(presences: ReservationPresence[], periode: Periode) {
    // Link together the recurrent events (split this into another function ?)
    // => Maybe rather compute & set groups on Presences, then simply read it here ?
    presences.forEach(prez => {
      if (prez.group) {
        return;
      }

      const recur = this.getRecurrentPresences(presences, prez, periode);

      if (recur.length > 1) {
        const groupId = this.generateId();

        recur.forEach(rpr => rpr.group = groupId);
      }
    });
  }

  buildSimpleEvent(presence: ReservationPresence) {
    const event = {
      reservation: presence.reservation,
      start: `${presence.date}T${presence.startTime}`,
      end: `${presence.date}T${presence.endTime2 || presence.endTime}`,
      presence: presence.id || presence.tmpId,
      title: presence.title,
      backgroundColor: presence.color,
      textColor: this.getContrastColor(presence.color)
    } as EventInput;

    if (presence.group) {
      event.groupId = presence.group;
    }

    return event;
  }

  buildEventsForPresences(presences: ReservationPresence[]) {
    return presences.map(pr => this.buildSimpleEvent(pr));
  }

  getContrastColor(color: string) {
    return '#' + mostReadable(color, textColors).toHex();
  }

  createPresenceFromRubrique(rubrique: PeriodeRubrique, date: string, customHours?: HoursData, mikado = false): ReservationPresence {
    const presence = {
      tmpId: this.generateId(),
      rubrique: rubrique.id,
      date,
      state: PresenceState.waiting,
      status: 'waiting'
    } as ReservationPresence;

    this.setPresenceAttributes(presence, rubrique);

    const rubHours = rubrique.horaires;

    if (mikado) {
      presence.startTime = customHours.start;
      presence.endTime = customHours.end;
    } else {
      // N'imp ... @TODO: rework, please ? :/
      presence.startTime = mikado || (rubrique.customStartTime && customHours?.start) ? customHours.start : rubHours[0].start;

      if (rubrique.horaires.length === 1) {
        presence.endTime = mikado || (rubrique.customEndTime && customHours?.end) ? customHours.end : rubHours[0].end;
      } else {
        // If has two hours (morning / afternoon) consider custom end hour is the total end (end of afternoon)
        presence.endTime = rubHours[0].end;
        presence.startTime2 = rubHours[1].start;
        presence.endTime2 = rubrique.customEndTime && customHours?.end ? customHours.end : rubHours[1].end;
      }
    }

    return presence;
  }

  createActivityPresence(activity: ActivityWithDay, rubrique: PeriodeRubrique, date: string) {
    const presence = this.createPresenceFromRubrique(rubrique, date);

    if (activity.startTime && activity.endTime) {
      presence.startTime = activity.startTime;
      presence.endTime = activity.endTime;
      presence.startTime2 = null; // cancel rubrique "second part" if any ...
      presence.endTime2 = null;
    }

    this.addActivityToPresence(presence, activity);
    this.setPresenceAttributes(presence, rubrique, activity);

    return presence;
  }

  setPresenceAttributes(presence: ReservationPresence, rubrique: PeriodeRubrique, activity?: PeriodeActivite) {
    presence.color = rubrique.color || rubrique.colorDomino;
    presence.title = rubrique.label || rubrique.name;
    presence.shortTitle = rubrique.shortCode || rubrique.code;

    presence.showTimes = rubrique.displayTimes;

    if (!rubrique.enabled && activity) {
      presence.title = activity.label || activity.name;
      presence.shortTitle = activity.code;
    }
  }

  clonePresence(presence: ReservationPresence) {
    const clone = Object.assign({}, presence);

    delete clone.id;
    clone.tmpId = this.generateId();

    return clone;
  }

  generateId() {
    return Math.random().toString(36).substr(2, 9);
  }

  createRecurrentPresencesAndClean(presences: ReservationPresence[], data: PlanningData) {
    const copiedPlusRecurrent: ReservationPresence[] = [];

    presences.forEach(pr => {
      const rubrique = data.currentPeriode.rubriques.find(r => r.id === pr.rubrique);
      const recurrentDates = this.getLinkedDates(pr.date, rubrique, data.currentPeriode).filter(d => data.isEditableDate(d));
      const recurrentPresences = this.copyPresences([pr], recurrentDates, data.currentPeriode, data.enabledActivities).concat(pr);

      if (recurrentPresences.length > 1) {
        const group = this.generateId();
        recurrentPresences.forEach(rpr => rpr.group = group);
      }

      copiedPlusRecurrent.push(pr);
      copiedPlusRecurrent.push(...recurrentPresences);
    });

    const cleaned: ReservationPresence[] = [];

    copiedPlusRecurrent.forEach(pr => {
      if (!cleaned.some(c => c.date === pr.date && c.rubrique === pr.rubrique)) {
        cleaned.push(pr);
      }
    });

    return cleaned;
  }

  // This method simply returns a set of "recopied presences" based on config. Further controls should be done when using its result :
  // - conflict
  // - date is "editable"
  copyPresences(presences: ReservationPresence[], dates: string[], periode: Periode, activities: PeriodeActivite[], checkExists = true, weekMode = false) {
    // Pre compute available activities
    const activitiesWithDay = this.getActivitiesWithDay(activities);

    const copies: ReservationPresence[] = [];

    presences.forEach(pr => {
      const rub = periode.rubriques.find(r => r.id === pr.rubrique);

      dates.forEach(date => {
        if (!this.isRubriqueOpenedForDate(rub, date, periode)) {
          return;
        }

        // Already copied (avoid lot of "false conflict")
        if (copies.some(ex => ex.date === date && ex.rubrique === pr.rubrique)) {
          return;
        }

        // Ignore presences that already exist ?
        if (checkExists && presences.some(ex => ex.date === date && ex.rubrique === pr.rubrique)) {
          return;
        }

        // New week mode is to copy monday to mondays, tuesdays to tuesdays, etc ...
        if (weekMode && moment(date).isoWeekday() !== moment(pr.date).isoWeekday()) {
          return;
        }

        const copyPrez = this.createPresenceFromRubrique(rub, date, {} as any, periode.type === 'mikado');

        copyPrez.color = pr.color;
        copyPrez.contrastColor = pr.contrastColor;

        // copy custom hours if any ...
        copyPrez.startTime = pr.startTime;
        copyPrez.endTime = pr.endTime;
        copyPrez.startTime2 = pr.startTime2;
        copyPrez.endTime2 = pr.endTime2;

        if (periode.type === 'diabolo' && activities) {
          const dayActivities = activitiesWithDay.filter(act => {
            return act.date === date && (rub.id === act.rubrique || this.hasRequiredPlages(rub.plages, act.plages));
          });

          dayActivities.forEach(act => {
            if (act.auto || pr.activities?.includes(act.id)) {
              this.addActivityToPresence(copyPrez, act);
            }
          });
        }

        // don't copy rubrique alone if was not enabled (activity presence)
        if (periode.type === 'mikado' || rub.enabled || copyPrez.activities?.length) {
          copies.push(copyPrez);
        }
      });
    });

    return copies;
  }

  isRubriqueOpenedForDate(rubrique: PeriodeRubrique, date: string, periode: Periode) {
    const periodeDay = periode.days.find(pd => pd.date === date);

    return periodeDay && (!periodeDay.rubriquesExclues?.includes(rubrique.id)) &&
      (!rubrique.openDays || rubrique.openDays.includes(moment(date).isoWeekday()));
  }

  hasRequiredPlages(plages: (PeriodePlage | number)[], requiredPlages: (PeriodePlage | number)[]) {
    if (!plages?.length || !requiredPlages?.length) {
      return false;
    }

    const ids = plages.map(x => x && typeof x === 'object' ? x.id : x);
    const requiredIds = requiredPlages.map(x => x && typeof x === 'object' ? x.id : x);

    return !requiredIds.find(x => !ids.find(y => y === x));
  }

  checkPresenceHasRoom(presence: ReservationPresence, periode: Periode) {
    const rubrique = periode.rubriques.find(r => r.id === presence.rubrique);
    const periodeDay = periode.days.find(pd => pd.date === presence.date);

    if (!this.checkRubriqueHasRoom(rubrique, periodeDay)) {
      return false;
    }

    // @NB: maybe Rubrique check would be enough ? Since a Presence always has a Rubrique,
    // and corresponding Activity shall be set on same Plages ...
    if (presence.activities) {
      // Retrieve every ActivityWithDay
      const awds = this.getDayActivities(periode.activites, periodeDay.date).filter(a => presence.activities.includes(a.id));

      if (awds.some(awd => !this.checkActivityHasRoom(awd, periodeDay))) {
        return false;
      }
    }

    return true;
  }

  checkRubriqueHasRoom(rubrique: PeriodeRubrique, periodeDay: PeriodeDay) {
    if (!periodeDay?.dispos) {
      return false;
    }

    const places = periodeDay.dispos.filter(dispo => this.hasRequiredPlages([dispo.plage], rubrique.plages));

    return places.every(pl => pl.places > 0);
  }

  checkActivityHasRoom(activity: ActivityWithDay, periodeDay: PeriodeDay) {
    if (activity.dispos < 1) {
      return false;
    }

    if (!periodeDay?.dispos) {
      return false;
    }

    const places = periodeDay.dispos.filter(dispo => this.hasRequiredPlages([dispo.plage], activity.plages));

    return places.every(pl => pl.places > 0);
  }

  isDateBetween(date: string, dateMin: string, dateMax: string = null) {
    return date >= dateMin && (!dateMax || !moment(dateMax).isValid() || date <= dateMax);
  }

  clearDuplicate(presences: ReservationPresence[]) {
    const cleared: ReservationPresence[] = [];

    presences.forEach(pr => {
      if (!cleared.find(exist => (exist.id ? exist.id === pr.id : exist.tmpId === pr.tmpId))) {
        cleared.push(pr);
      }
    });

    return cleared;
  }

  getPresenceDetails(presence: ReservationPresence, planningData: PlanningData, reservation?, consumer?) {
    reservation = reservation || planningData.findReservation(presence.reservation);
    consumer = consumer || planningData.findConsumer(reservation.idConsumer);
    const subtitle = [reservation.etablissement, reservation.accueil, reservation.periode].filter(x => !!x).join(' / ');

    const contrastColor = presence.color ? this.getContrastColor(presence.color) : '';
    const colorClass = this.isCanceledOrDenied(presence, true) ? 'warn' : (presence.status === 'accepted' ? 'success-color' : 'primary');

    const activities = presence.activities?.map(a => {
      const act = planningData.findActivity(a, reservation.idPeriode);
      act.listeAttente = presence.activitiesOnListeAttente?.includes(a);
      return act;
    });

    return { ...presence, reservation, consumer, contrastColor, activities, subtitle, colorClass };
  }

  updateRooms(presence: ReservationPresence, periode: Periode, removing = false) {
    if (!periode.liveCapacity) {
      return;
    }

    // Rubrique rooms
    const rub = periode.rubriques.find(r => r.id === presence.rubrique);
    const day = periode.days.find(d => d.date === presence.date);
    const plages = rub.plages.map(p => p.id);

    if (day.dispos){ // because dispos is not set in admin mode
      day.dispos.filter(d => plages.includes(d.plage)).forEach(d => removing ? d.places++ : d.places--);
    }

    // Activity rooms
    periode.activites.filter(a => presence.activities?.includes(a.id)).forEach(act => {
      act.days.filter(ad => ad.date === presence.date).forEach(ad => removing ? ad.nbPlaces++ : ad.nbPlaces--);
    });
  }
}
