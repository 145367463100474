import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AboutComponent } from './components/_public/about/about.component';
import { HomeComponent } from './components/_public/home/home.component';
import { LoginComponent } from './components/_public/login/login.component';
import { LogoutComponent } from './components/_public/login/logout.component';
import { ForgottenPasswordComponent } from './components/_public/login/forgotten-password/forgotten-password.component';
import { UpdatePasswordComponent } from './components/_user/update-password/update-password.component';
import { AuthenticationGuard } from './guards/authentication.guard';
import { Role } from './models/user';
import { NewsListComponent } from './components/_public/news';
import { PermissionGuard } from './guards/permission.guard';
import { SingleNewsComponent } from './components/_public/news/single-news/single-news.component';
import { UserEditComponent } from './components/_public/user-edit/user-edit.component';
import { PaymentBackComponent } from './components/_public/payment/back/payment-back.component';
import { NewsByCategoryComponent } from './components/_public/news/news-by-category/news-by-category.component';

const routes: Routes = [

  { path: 'home', component: HomeComponent },
  { path: '', pathMatch: 'full', component: HomeComponent },
  { path: 'go/:token', component: HomeComponent },
  { path: 'dominoConnect/:token', component: HomeComponent, data: { dominoConnect: true } },

  {
    path: 'admin',
    loadChildren: () => import('./modules/admin.module').then(m => m.AdminModule),
    canLoad: [AuthenticationGuard],
    data: { roles: [Role.Admin], title: 'Administration' }
  },
  { path: 'login', component: LoginComponent, data: { title: 'Connexion' } },
  { path: 'logout', component: LogoutComponent },
  { path: 'forgot-password', component: ForgottenPasswordComponent },
  { path: 'reset-password', component: UpdatePasswordComponent, data: { title: 'Réinitialisation du mot de passe' } },
  {
    path: 'register', component: UserEditComponent, data: { title: 'Nouveau compte', perm: 'account_create' },
    canActivate: [PermissionGuard]
  },

  { path: 'news', component: NewsListComponent, data: { title: 'Actualités' } },
  { path: 'news/:id', component: SingleNewsComponent, data: { title: 'Actualité' } },
  { path: 'news/categories/:idCategories', component: NewsByCategoryComponent, data: { title: 'Actualité' } },
  { path: 'about', component: AboutComponent, data: { title: 'A propos' } },

  { path: 'paiement/retour/:type/:module', component: PaymentBackComponent },
  { path: 'paiement/retour/:type', component: PaymentBackComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
