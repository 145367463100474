import { Component, OnInit, OnDestroy } from '@angular/core';
import { Child } from '@app/models/child';
import { ChildService } from '@app/services/child.service';
import { PermissionService } from '@app/services/permission.service';
import { FormConfigService } from '@app/services/form-config.service';
import { map, filter, takeUntil, switchMap, tap } from 'rxjs/operators';
import { PlatformService } from 'src/app/services/platform.service';
import { Router } from '@angular/router';
import { FamilyService } from '@app/services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user-children',
  templateUrl: './children-list.component.html',
  styleUrls: ['./children-list.component.scss']
})
export class ChildrenListComponent implements OnInit, OnDestroy {

  children: Child[];
  formParts: any[];
  readOnly: boolean;
  permToCreate: boolean;

  onDestroy$ = new Subject();

  constructor(
    private childService: ChildService,
    private familyService: FamilyService,
    private formConfigService: FormConfigService,
    public permService: PermissionService,
    public platformService: PlatformService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.readOnly = !this.permService.hasPermission('child_edit') || !this.familyService.currentFamily.active;
    this.permToCreate = this.permService.hasPermission('child_create') && this.familyService.currentFamily.active;
    // Know which form sections are enabled
    this.loadFormSteps().subscribe(stepNames => this.formParts = stepNames);

    this.familyService.currentFamily$.pipe(
      filter(f => !!f),
      takeUntil(this.onDestroy$),
      tap(_ => this.children = null), // reset current data
      switchMap(f => this.loadChildren(f))
    ).subscribe();
  }

  loadChildren(f) {
    return this.childService.getFamilyChildren(f).pipe(tap(data => this.children = data));
  }

  loadFormSteps() {
    const filteredSteps = ['validation']; // steps excluded in "read / edit" (not create) modes
    return this.formConfigService.getForm('form-enfant').pipe(
      map(steps => steps
        .filter(step => (!filteredSteps.includes(step.stepName)))
        .map(step => ({ stepName: step.stepName, label: step.label }))
      )
    );
  }

  openForm(childId, stepName) {
    this.router.navigate([`/account/children/edit/${childId}/${stepName}`]);
  }

  // onDeleteClick(child: Child) {
  //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //     width: '400px',
  //     data: { message: `Voulez vous supprimer l'enregistrement de cet enfant ?` }
  //   });

  //   dialogRef.afterClosed().subscribe(res => res ? this.delete(child) : null);
  // }

  // delete(child: Child) {
  //   this.childService.delete(child).subscribe(_ => {
  //     const data = this.children;
  //     const index = data.findIndex(each => each.id === child.id);
  //     if (index !== -1) {
  //       data.splice(index, 1);
  //       this.children = data;
  //     }
  //   });
  // }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
