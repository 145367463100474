import { Component, OnInit, Inject, ViewChildren, ElementRef, QueryList, AfterViewInit } from '@angular/core';
import { LocaleService } from './services/locale.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, first, distinctUntilChanged, take, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, forkJoin, of } from 'rxjs';
import { ThemeService } from './services/theme.service';
import { AuthenticationService, BoardConfigService, GlobalConfigService, PermissionService, SnackbarService, UserService } from './services';
import { FamilyService } from './services/family.service';
import moment from 'moment';
import { DOCUMENT } from '@angular/common';
import { Role } from './models/user';
import { CacheService } from './services/cache.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ConfirmEmailComponent } from './components/_elements/confirm-email/confirm-email.component';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { HeaderService } from './services/header.service';
import { BoardConfig } from './models/board-card';
import { CookieConfig } from './models/global-config';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  accountBoard: BoardConfig;
  AssmatActivedAccountPerm: boolean;

  title = 'portail';
  redirectToUser = false;

  // This used to be a Subject / Observable but happens only once, so for now keep it simple
  loaded = false;

  @ViewChildren('themeWrapper') themeWrapper: QueryList<ElementRef>;

  themeClass = 'light-theme';
  customOrDefaultClass = 'default-theme';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private localeService: LocaleService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AuthenticationService,
    private userService: UserService,
    private familyService: FamilyService,
    private globalConfigService: GlobalConfigService,
    private headerService: HeaderService,
    private cacheService: CacheService,
    private boardConfigService: BoardConfigService,
    private authService: AuthenticationService,
    private snackbarService: SnackbarService,
    private permService: PermissionService,
    private bottomSheet: MatBottomSheet,
    private cookieService: NgcCookieConsentService,
    private themeService: ThemeService,
    private overlayContainer: OverlayContainer
  ) { }

  ngOnInit() {
    moment.locale('fr');
    this.initApp();
    this.cacheService.initNewAppChecker();

    const overlayContainerElement = this.overlayContainer.getContainerElement();

    this.themeService.darkMode$.subscribe(darkMode => this.themeClass = darkMode ? 'dark-theme' : 'light-theme');
    this.themeService.isCustom$.subscribe(isCustom => this.customOrDefaultClass = (isCustom ? 'custom' : 'default') + '-theme');

    merge(
      this.themeService.darkMode$,
      this.themeService.isCustom$
    ).subscribe(_ => {
      overlayContainerElement.classList.remove('dark-theme', 'light-theme', 'custom-theme', 'default-theme');
      overlayContainerElement.classList.add(this.themeClass, this.customOrDefaultClass);
    });
  }

  ngAfterViewInit() {
    this.themeWrapper.changes.pipe(
      map(res => res.first),
      filter(x => !!x),
      first()
    ).subscribe(el => this.exposePrimaryColorForTimepicker(el));
  }

  initApp() {
    this.localeService.init();

    if (location.href.includes('dominoConnect')) {
      this.handleDominoUserRedirect();
    } else {
      // don't immediately check for user session if is connecting from Domino
      this.startSessionChecker();
    }

    // Contains every loader required for app starts in good condition (theme, globalConfig)
    // (/!\ Should avoid too long loading ...)
    // @NB: GlobalConfig contains PermissionConfig, which is needed in Routes config itself, so better be loaded at start
    const appLoaders: Observable<any>[] = [
      this.themeService.loadTheme(),
      this.globalConfigService.init()
    ];

    forkJoin(appLoaders).subscribe(_ => {
      this.loaded = true;
      this.clearRootSpinner();
      this.showCookieConsent();
    });

    this.refreshUserDataOnChange();

    this.accountService.accountType$.pipe(
      switchMap(type => type ? this.boardConfigService.get(type === 'family' ? 'user' : 'assmat') : of(null))
    ).subscribe(conf => {
      this.accountBoard = conf;
      this.headerService.refreshPageTitle(this.activatedRoute, this.router.url, this.accountBoard);
    });

    merge(
      this.translateService.onLangChange,
      this.router.events.pipe(filter(e => e instanceof NavigationEnd))
    ).subscribe(() => {
      this.headerService.refreshBreadcrumbs(this.activatedRoute.root);
      this.headerService.refreshPageTitle(this.activatedRoute, this.router.url, this.accountBoard);
    });
  }

  refreshUserDataOnChange() {
    // Watch for User logs in/out and update linked data
    // => Loads User data from API when app starts, no use for localStorage.currentUser then ?
    this.accountService.currentUser$.pipe(
      // A "real" user change is when the User.id changes
      distinctUntilChanged((x, y) => x?.id === y?.id)
    ).subscribe(user => {

      if (user) {
        // @TODO: maybe move these to "login" response, could even merge these data directly in "User" .. ?

        // If User is linked to an Adulte, load it (better always be the case, for now)
        if (user.role === Role.User && user.idAdulte) {
          // Reload the Adulte linked to current User, only when needed ...
          this.userService.reloadCurrentAdulte().subscribe(adulte => {

            if (!adulte.emailConfirm && !this.userService.userCreated) {
              this.bottomSheet.open(ConfirmEmailComponent);
            }

            if (adulte.families) {
              this.familyService.defineCurrentFamily(adulte.families);
            }

            if (adulte.assistantMaternel) {
              this.accountService.storeIdAssmat(adulte.assistantMaternel.idAssistantMaternel);
            }

            this.permService.permission$.subscribe(perm => {
              if (!perm.enabled_assmat_account) {
                this.accountService.setAccountType('family');
              } else {
                this.accountService.setAccountType(user.accountType ||
                  (adulte.assistantMaternel && localStorage.getItem('remember-account-type') === 'assmat' ? 'assmat' : this.familyService.currentFamily ? 'family' : "assmat"));
              }
            })
          });
        }

      } else {
        // Logout => clear everything
        this.userService.setCurrentAdulte(null);
        this.familyService.setCurrentFamily(null);
      }
    });
  }

  showCookieConsent() {
    this.globalConfigService.getPart<CookieConfig>('cookie').pipe(
      take(1),
      map(config => this.globalConfigService.getCookieConsentFromConfig(config))
    ).subscribe(config => {
      this.cookieService.init(config);
    });
  }

  exposePrimaryColorForTimepicker(el: ElementRef) {
    const themeWrapper = el.nativeElement;

    if (themeWrapper) {
      const primaryColor = getComputedStyle(themeWrapper).getPropertyValue('--primary');
      document.body.style.setProperty('--timepicker-primary', primaryColor);
    }
  }

  clearRootSpinner() {
    // Supprimer le loader de l'application
    const rootLoaderSpinner = this.document.querySelector('.root-loader-container');

    if (!!rootLoaderSpinner) {
      rootLoaderSpinner.remove();
    }
  }

  handleDominoUserRedirect() {
    this.redirectToUser = true;

    this.authService.logout(false).subscribe();

    const token = location.href.split('/').pop();

    this.userService.getTokenType(token).subscribe(response => {
      if (response.type === 'dominoUserConnect' && response.user) {
        this.authService.updateUser(response.user, true);
        this.router.navigate(response.user.role === Role.Admin ? ['/admin'] : ['/account']);
      } else {
        this.snackbarService.error(`Erreur, la requête n'a pas renvoyé l'utilisateur`);
        this.router.navigate(['/home']);
      }

      this.startSessionChecker();

      this.redirectToUser = false;
    });
  }

  startSessionChecker() {
    this.authService.currentUser$.pipe(
      // only when user really changes (logs in/out), refresh "sessionChecker"
      distinctUntilChanged((x, y) => x?.id === y?.id)
    ).subscribe(user => this.authService.setSessionChecker());
  }
}
