import { Component, OnDestroy, OnInit, AfterContentChecked, ViewContainerRef, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { environment } from '@env/environment';
import { MenuService } from '@app/services/menu.service';
import { AuthenticationService } from '@app/services/authentication.service';
import { PlatformService } from '@app/services/platform.service';
import { FamilyService } from '@app/services/family.service';
import { merge, of, Subject } from 'rxjs';
import { GlobalConfigService } from '@app/services';
import { HeaderService } from '@app/services/header.service';
import { HeaderConfig } from '@app/models/global-config';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterContentChecked, OnDestroy {
  modeDebug = environment.modeDebug;

  previousUrl: string;
  destroy$ = new Subject();

  reservationsToPay: number;

  config: HeaderConfig;

  themeSwitchEnabled: boolean;
  hideBackButton: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private globalConfigService: GlobalConfigService,
    private familyService: FamilyService,
    private titleService: Title,
    private translateService: TranslateService,
    private router: Router,

    public menuService: MenuService,
    public platformService: PlatformService,
    public headerService: HeaderService,
  ) {
  }

  ngOnInit() {
    this.globalConfigService.getPart<HeaderConfig>('header').subscribe(conf => {
      this.config = conf;
      this.headerService.setFavicon(conf.favicon);
    });

    this.headerService.favicon$.subscribe(icon => this.writeFavicon(icon));

    this.headerService.pageTitle$.pipe(
      switchMap(value => value ? this.translateService.get(value) : of(''))
    ).subscribe(title => this.titleService.setTitle(title));
  }

  ngAfterContentChecked() {
    this.familyService.reservationsToPay$.subscribe(rp => this.reservationsToPay = rp);
  }

  logout() {
    this.authenticationService.logout().subscribe();
  }

  writeFavicon(url: string) {
    // find all icons, including "apple-touch-icon"
    const favHeaders = document.querySelectorAll('link[rel~="icon"]');

    if (favHeaders.length) {
      favHeaders.forEach(tag => tag.setAttribute('href', url));
    }
  }

  startPreventBackButtonDetection() {
    merge(
      of(this.router.url),
      this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        map((e: NavigationEnd) => e.url))
    ).pipe(
      map(url => url.startsWith('/') ? url.substring(1) : url),
      takeUntil(this.destroy$),
    ).subscribe(url => this.hideBackButton = ['home', ''].includes(url));
  }

  onBack() {
    history.back();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
