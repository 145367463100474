import { Injectable } from '@angular/core';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { NavItem } from '@app/models/nav-item';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiCrudService } from './api-crud.service';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MenuService extends ApiCrudService<NavItem>{

  url = 'conf/menu';

  sidenav: MatSidenav;
  mainContent: MatSidenavContent;
  menuItems$ = new BehaviorSubject<NavItem[]>(null);

  constructor(
    protected http: HttpClient,
  ) {
    super();
  }

  getAll() {
    return super.getAll().pipe(
      map((res: any) => 'items' in res ? res.items : res)
    );
  }

  initMenuItems() {
    if (!this.menuItems$.value) {
      this.getAll().subscribe(links => this.menuItems$.next(links));
    }
  }

  updateMenuItems(items: NavItem[]): Observable<any> {
    return this.update({ items }).pipe(
      tap(_ => this.menuItems$.next(JSON.parse(JSON.stringify(items))))
    );
  }

  getChangelog() {
    return this.http.get('/assets/CHANGELOG.md', {
      responseType: 'text',
      headers: new HttpHeaders({ 'Content-Type': 'text/plain' })
    });
  }
}
