import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExtendedFormlyFieldConfig, StepType } from '@app/forms/config/form-model';
import { AssmatAvailability, AssmatAvailabilityAgrements, AssmatComplement, AssmatFormModel, AssmatModeAccueil } from '@app/models/ass-mat';
import { FormConfigService, PermissionService, UserService } from '@app/services';
import { AssmatService } from '@app/services/assmat.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-assmat-info',
  templateUrl: './assmat-info.component.html',
  styleUrls: ['./assmat-info.component.scss']
})
export class AssmatInfoComponent implements OnInit {

  // Data
  idAssmat: number;
  loading: boolean = true;
  assmatData: any;
  modeAccueil: AssmatModeAccueil;
  complement: AssmatComplement;
  modeAccueilList: string[] = [];
  valeursLibres: any[] = [];
  availability: AssmatAvailability[];
  permToEditDisponibilites: boolean;
  permToEditSouhaitsAccueil: boolean;
  listValeursLibres: any[] = [];

  // Step names
  assmatFormSteps: any[];

  constructor(
    private router: Router,
    private userService: UserService,
    private formConfigService: FormConfigService,
    private assmatService: AssmatService,
    private permService: PermissionService

  ) { }

  ngOnInit(): void {
    this.permToEditSouhaitsAccueil = this.permService.hasPermission('informations_assmat_edit');
    this.permToEditDisponibilites = this.permService.hasPermission('disponibilites_edit');
    this.idAssmat = this.userService.currentUser.idAssmat || null;

    forkJoin([
      this.assmatService.getAssmatById(this.idAssmat),
      this.assmatService.getAvailability(this.idAssmat),
      this.formConfigService.getForm('form-assmat')
    ]).subscribe(([assmatFormData, dataAvailability, formConfig]: [AssmatFormModel, AssmatAvailabilityAgrements, StepType[]]) => {
      this.assmatData = assmatFormData;

      this.modeAccueil = assmatFormData.Bloc_mode_accueil;
      this.complement = assmatFormData.Bloc_complement;
      this.valeursLibres = assmatFormData.Bloc_libre;
      this.availability = dataAvailability.disponibilites;
      this.assmatFormSteps = this.getFormattedSteps(formConfig);
      this.listValeursLibres = this.getFormattedValeursLibres(formConfig);
      this.loading = false;
    })
  }

  getFormattedSteps(steps: StepType[], excludedNames?: string[]) {
    return steps
      .filter(step => !excludedNames || !excludedNames.includes(step.stepName))
      .map(step => ({ stepName: step.stepName, label: step.label }));
  }

  getFormattedValeursLibres(form: StepType[]) {
    let valeursLibresFields: ExtendedFormlyFieldConfig[] = [];
    let formattedValeursLibres: any[] = [];
    let valeursLibresStep = form.find(formStep => formStep.stepName === "valeursLibres");
    if (valeursLibresStep) {
      let listKeys = this.formConfigService.getKeysListOfOneStep(valeursLibresStep);

      listKeys.forEach(key => {
        valeursLibresFields.push(this.formConfigService.findFieldByName(valeursLibresStep.fields, key));
      });

      let mapValeursLibresFields = valeursLibresFields.map(val => {
        return { key: val.key, label: val.templateOptions.label };
      }).filter(val => val.key !== "message");

      mapValeursLibresFields.forEach(val => {
        for (const key in this.valeursLibres) {
          if (Object.prototype.hasOwnProperty.call(this.valeursLibres, key)) {
            const value = this.valeursLibres[key];
            if (key === val.key) {
              formattedValeursLibres.push({ label: val.label, value })
            }
          }
        }
      });
      return formattedValeursLibres;
    }
  }

  openForm(stepName) {
    const path = `/account/assmat/${stepName}`;
    this.router.navigate([path]);
  }

}
