import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { StepType, ExtendedFormlyFieldConfig, ExtendedFormlyFormOptions } from '@app/forms/config/form-model';
import { MatStepper, MatHorizontalStepper } from '@angular/material/stepper';
import { PlatformService } from '@app/services/platform.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HeaderService } from '@app/services/header.service';

export interface SubmitFormEvent {
  model: object | any;
  form: FormArray;
}

@Component({
  selector: 'app-form-viewer',
  templateUrl: './form-viewer.component.html',
  styleUrls: ['./form-viewer.component.scss']
})
export class FormViewerComponent implements OnInit, OnChanges {

  @Input() activedStep = 0;

  @Input() formFields: StepType[];
  @Input() admin = false;
  @Input() model = {};
  @Input() readOnly = false;
  @Input() formTitle: string;

  @Input() containerWidth = this.platformService.mainWidth(); // for preview in Admin mode
  @Input() verticalStepperBeakpoint = 800;


  @Output() save = new EventEmitter();
  @Output() stepChange = new EventEmitter();
  @Output() modelChange = new EventEmitter();

  form: FormArray;
  options: ExtendedFormlyFormOptions[];

  errorMessage = '';

  @ViewChild(MatHorizontalStepper) stepper: MatStepper;

  // Suspend submit while form init, to avoid too early validation, and error "Expression has changed after it was checked"
  suspendSubmit = true;

  constructor(
    private headerService: HeaderService,

    public platformService: PlatformService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.initForm();
    if (!this.admin) {
      this.platformService.mainWidth$.pipe(tap(mainWidth => this.containerWidth = mainWidth)).subscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formTitle) {
      this.headerService.setCurrentPageTitle(this.formTitle);
    }
  }

  initForm() {
    this.form = new FormArray(this.formFields.map(_ => new FormGroup({})));
    this.options = this.formFields.map((step) => {
      return ({
        formState: {
          disabled: this.readOnly,
          selectOptionsData: step.selectOptionsData || null
        },
        containerWidth: this.containerWidth,
        adminMode: this.admin
      } as ExtendedFormlyFormOptions);
    });

    if (!this.model) { this.model = {}; }

    if (this.readOnly) {
      setTimeout(() => this.form.disable());
    } else {
      setTimeout(() => this.suspendSubmit = false);
    }
  }

  onEnter(event: KeyboardEvent) {

    if (this.readOnly) {
      return;
    }

    // @TODO: this diserves a clean ...
    if (this.formFields.length === 1) {
      this.validate(0);
      return;
    }

    const target = event.target as HTMLElement;

    if (target.localName === 'textarea') {
      return;
    }

    event.preventDefault();

    const currentStep = this.stepper.selectedIndex;
    this.validate(currentStep); // @TODO: fix, doesn't work on last step .. ?

    if (currentStep === this.formFields.length - 1) {
      return;
    }

    this.stepper.next();
  }

  // Mark all fields as "touched" to notice the user of fields with error, even ones he didn't touch (mostly required)
  validate(index) {
    if (this.admin) {
      return;
    }

    if (!this.form.at(index).valid) {
      this.markAllTouched(this.form.at(index) as FormGroup);
    }
  }

  markAllTouched(formGroup: FormGroup | FormArray) {

    const controls = formGroup instanceof FormArray ? formGroup.controls : Object.keys(formGroup.controls).map(k => formGroup.controls[k]);

    controls.forEach(control => {
      control.markAsTouched();

      if ((control instanceof FormGroup || control instanceof FormArray) && control.controls) {
        this.markAllTouched(control);
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.save.emit({ model: this.model, form: this.form } as SubmitFormEvent);
    }
  }

  reset() {
    this.form.reset();
    this.form.clear();
  }

  onStepChange($event) {
    this.activedStep = $event.selectedIndex;
    this.stepChange.emit(this.activedStep);
  }

  onModelChange($event) {
    this.modelChange.emit($event);
  }

  logForm() {
    console.log('form', this.form);
    console.log('this.model', this.model);
  }

  setErrorMessage(errorMessage){
    console.log('setErrorMessage',errorMessage)
    setTimeout(()=>{
      this.errorMessage = errorMessage;
    });
  }
}
