import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ApiCrudService } from './api-crud.service';
import { map } from 'rxjs/operators';

export interface UploadedFile {
  id: number;
  name: string;
  type: string;
  url: string;
  creationDate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class FileService extends ApiCrudService<UploadedFile> {

  url = 'files';

  constructor(
    protected http: HttpClient
  ) {
    super();
  }

  upload(data: FormData) {
    return this.http.post(this.url, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };
          case HttpEventType.Response:
            return { ...{ type: '' }, ...event.body };
          default:
            return 'Unhandled event ' + event.type;
        }
      })
    );
  }
}
