<div class="conteneur" *ngIf="children; else loader">
  <div fxLayout="row wrap">

    <mat-card *ngFor="let child of children" class="card" fxFlex="48.5" fxFlex.sm="48" fxFlex.xs="100">
      <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title fxLayout="row" fxLayoutAlign="start center">
          <mat-icon style="margin-right: 5px;">person</mat-icon>
          <span>{{ child.prenom }} {{ child.nom }}</span>
        </mat-card-title>
        <button style="margin-bottom: 20px;" mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-card-header>
      <mat-menu class="mat-menu-child" #menu="matMenu" xPosition="before">

        <button *ngFor="let formPart of formParts" mat-menu-item (click)="openForm(child.id, formPart.stepName)">
          <mat-icon color="primary">{{ readOnly ? 'visibility' : 'edit' }}</mat-icon>
          {{ formPart.label }}
        </button>

        <!-- <button mat-menu-item color="warn" *ngIf="permService.hasPermission('child_edit')"
          (click)="onDeleteClick(child)">
          <mat-icon color="warn">delete</mat-icon> {{ 'enfant.delete'|trans }}
        </button> -->

      </mat-menu>
      <mat-card-content style="margin-left: 20px;">
        <p *ngIf="child.dateNaissance"><b>Date de naissance : </b>{{ child.dateNaissance|date:'shortDate' }}</p>
        <p *ngIf="child.age"><b>Age : </b>{{ child.age.years }} {{ child.age.years > 1 ? 'ans' : 'an' }}
          {{ child.age.months ? 'et ' + child.age.months + ' mois' : '' }}</p>
        <p *ngIf="child.lieuNaissance"><b>Lieu de naissance : </b>{{ child.lieuNaissance }} ({{ child.depNaissance }})
        </p>
      </mat-card-content>
    </mat-card>

    <ng-container [ngTemplateOutlet]="addCard"></ng-container>
  </div>

  <div *ngIf="!children.length && !permToCreate">
    <h2 style="text-align: center;">{{ 'enfant.empty_list'|trans }}</h2>
  </div>
</div>

<ng-template #loader>
  <app-loader [message]="'enfant.loading_data'|trans" class="margin"></app-loader>
</ng-template>

<ng-template #addCard>
  <mat-card *ngIf="permToCreate" class="card card-add" fxFlex="48.5" fxFlex.sm="48" fxFlex.xs="100">
    <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="center center">
      <mat-card-title fxLayout="row" fxLayoutAlign="center center">
        {{ 'enfant.add'|trans }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content style="margin-top: 20px;" fxLayout="row" fxLayoutAlign="center center">
      <button mat-fab color="primary" [matTooltip]="'enfant.add'|trans" routerLink="/account/children/add">
        <mat-icon>add</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>
</ng-template>
