import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface PortailConfig { }

@Injectable({
  providedIn: 'root'
})
export class BaseConfigService {

  configUrl = 'conf/';

  cachedConfigs = {};

  constructor(
    private http: HttpClient
  ) { }

  get<T extends PortailConfig>(type: string): Observable<T> {
    if (this.cachedConfigs[type]) {
      return of(this.cachedConfigs[type]);
    }

    return this.http.get<T>(this.configUrl + type).pipe(
      tap(conf => this.cachedConfigs[type] = conf)
    );
  }

  // @NB: generally only for admin !
  set<T extends PortailConfig>(type: string, value: T) {
    return this.http.put(this.configUrl + type, value);
  }
}
