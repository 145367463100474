import { Injectable } from '@angular/core';
import { ApiCrudService } from './api-crud.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Link, CustomLinkItem } from '@app/models/custom-link';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LinkService extends ApiCrudService<Link> {

  url = 'conf/links';

  linkItems$ = new BehaviorSubject<CustomLinkItem[]>(null);

  constructor(
    protected http: HttpClient
  ) {
    super();
  }

  getAll() {
    return super.getAll().pipe(
      map((res: any) => 'links' in res ? res.links : res)
    );
  }

  initLinks() {
    if (!this.linkItems$.value) {
      this.getAll().subscribe(links => this.linkItems$.next(links));
    }
  }

  updateLinks(links: CustomLinkItem[]): Observable<any> {
    return this.update({links}).pipe(
      tap(_ => this.linkItems$.next(JSON.parse(JSON.stringify(links))))
    );
  }
}
