import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpEvent } from '@angular/common/http';
import { GedConfig, GedPiece, GedDocument } from '@app/models/ged';
import { ApiCrudService } from './api-crud.service';
import { FamilyService } from './family.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GedService extends ApiCrudService<any>{

  configUrl = 'conf/ged';
  url = 'ged';

  constructor(
    protected http: HttpClient,
    private familyService: FamilyService
  ) {
    super();
  }

  getConfig() {
    return this.http.get<GedConfig>(this.configUrl, this.httpOptions);
  }

  updateConfig(config: GedConfig | any) {
    return this.http.put(this.configUrl, { config }, this.httpOptions);
  }

  sendDocument(file: File, piece: GedPiece, idEntite: number) {

    const formData = new FormData();

    formData.set('file', file);
    formData.set('type', file.type);

    formData.set('entite', piece.obj);
    formData.set('idPieceAFournir', piece.id + '');

    formData.set('idEntite', idEntite + '');

    return this.http.post(`familles/${this.familyService.currentFamily.id}/documents`, formData, {
      reportProgress: true,
      observe: 'events',
    }).pipe(map(event => this.mapProgressResponse(event)));
  }

  getCurrentFamilyDocuments() {
    return this.http.get<GedDocument[]>(`familles/${this.familyService.currentFamily.id}/documents`);
  }

  getDocument(idDocument) {
    return this.http.get(`documents/${idDocument}`, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events'
    }).pipe(map(response => this.mapProgressResponse(response)));
  }

  mapProgressResponse(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.DownloadProgress:
        return { type: 'dl_progress', value: Math.round(100 * event.loaded / event.total) };
      case HttpEventType.UploadProgress:
        return { type: 'up_progress', value: Math.round(100 * event.loaded / event.total) };
      case HttpEventType.Response:
        return { type: 'response', value: event.body };
      default:
        return { type: 'otherType', value: event.type };
    }
  }
}
