import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MenuService } from '@app/services/menu.service';
import { AuthenticationService, GlobalConfigService, UserService, FamilyService, SnackbarService, TypeSnackbar, PermissionService, ThemeService } from '@app/services';
import { PlatformService } from '@app/services/platform.service';
import { HeaderConfig } from '@app/models/banner';
import { NavItem } from '@app/models/nav-item';
import { Role, User } from '@app/models/user';
import { Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Adulte } from '@app/models/adulte';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { environment } from '@env/environment';
import { MatDialog } from '@angular/material/dialog';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {

  @Input() compact = false;
  @Input() previewMode = false;
  @Input() items: NavItem[];

  headerConfig: HeaderConfig;
  themeSwitchEnabled: boolean;

  accountSwitchDisabled: boolean;
  account: 'assmat' | number;
  enabledAssmatAccount: boolean;

  currentUser: User;
  currentAdulte: Adulte;

  env = environment;
  marked: any;

  onDestroy$ = new Subject();

  constructor(
    private userService: UserService,
    private familyService: FamilyService,
    private activatedRoute: ActivatedRoute,
    private snackbarService: SnackbarService,
    private accountService: AuthenticationService,
    private themeService: ThemeService,
    private dialog: MatDialog,

    public globalConfigService: GlobalConfigService,
    public platformService: PlatformService,
    public menuService: MenuService,
    public permService: PermissionService,
    public router: Router,
  ) { }

  ngOnInit() {

    if (this.env.changelog) {
      import('marked').then(m => this.marked = m.parse);
    }

    this.enabledAssmatAccount = this.permService.hasPermission('enabled_assmat_account');

    if (!this.previewMode) {
      this.menuService.initMenuItems();
    }

    this.globalConfigService.getPart('header').subscribe((conf: HeaderConfig) => this.headerConfig = conf || new HeaderConfig());

    this.themeService.darkModeSwitchEnabled$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(x => this.themeSwitchEnabled = x);

    this.accountService.currentUser$.subscribe(data => {
      this.currentUser = data;

      if (this.currentUser?.accountType === 'assmat' && this.enabledAssmatAccount) {
        this.account = 'assmat';
      }

      if (this.currentUser?.accountType === "family") {
        this.familyService.currentFamilyReadyOnce$.subscribe(f => {
          this.account = f.id;
        });
      }
    });


    this.userService.currentAdulte$.subscribe(data => this.currentAdulte = data);

    if (!this.compact) {
      this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        startWith(true)
      ).subscribe(_ => this.accountSwitchDisabled = this.isRouteAccountSwitchDisabled(this.activatedRoute));
    }
  }

  showToUser(item: NavItem) {
    const logged = this.accountService.isAuthenticated;
    return !item.limited || (item.limited === 'login' && logged) || (item.limited === 'logout' && !logged);
  }

  showDependOnRole(item: NavItem) {
    const isAdmin = this.accountService.hasRole(Role.Admin);
    return !item.role || (item.role === "admin" && isAdmin) || (item.role === "user" && !isAdmin);
  }

  // /!\ Recursive call ! (inspired of AppComponent Router.events listener)
  isRouteAccountSwitchDisabled(route: ActivatedRoute) {
    const conf = route.routeConfig;

    if (conf && conf.data && conf.data.disableAccountSwitch) {
      return true;
    }

    return route.firstChild ? this.isRouteAccountSwitchDisabled(route.firstChild) : false;
  }

  logout() {
    this.accountService.logout().subscribe();
  }

  onMenuLinkClick() {
    if (this.platformService.isMobile) {
      this.menuService.sidenav.close();
    }
  }

  onCustomItemClick(item: NavItem, event: MouseEvent) {
    event.preventDefault();

    if (this.previewMode) {
      this.snackbarService.open({
        message: 'Lien inactif pendant la configuration',
        type: TypeSnackbar.info,
        textButton: 'Ok'
      });
      return false;
    }

    if (this.platformService.isMobile) {
      this.menuService.sidenav.close();
    }

    if (item.linkUrlApiDoc) {
      window.open(environment.apiUrl + item.linkUrlApiDoc, item.target);
    } else {
      window.open(item.linkUrl, item.target);
    }
  }

  onChangeAccountType(value: string | number) {
    if (value === 'assmat') {
      this.accountService.setAccountType(value);
    } else if (typeof (value) === 'number') {
      this.accountService.setAccountType('family');
      this.familyService.setCurrentFamily(this.currentAdulte.families.find(f => f.id === value));
    }
    this.router.navigate(['account']);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  showChangelog() {
    if (this.env.changelog) {
      this.menuService.getChangelog().subscribe(content => {
        const html = this.marked(content);

        this.dialog.open(HelpDialogComponent, {
          data: { title: 'Changements', message: html }
        })
      });
    }
  }
}
