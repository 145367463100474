<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" [cdkDragDisabled]="platformService.isMobile">
  <mat-toolbar mat-dialog-title color="primary" cdkDragHandle>
    <button class="close-dialog" mat-icon-button [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
    <span class="title">
      {{ date|date:'EEEE dd MMMM y'|titlecase }}
    </span>
  </mat-toolbar>
</div>

<div mat-dialog-content class="thin-scrollbar">
  <div class="new-presence-header">
    <label>Rubrique : </label>{{ rubrique?.name }}
  </div>

  <div>
    <div class="time-input">
      <label>Heure d'arrivée : </label>
      <app-time-input [(ngModel)]="times.start" [minuteGap]="periode.intervalleMinHoraire" [picker]="true"
        [min]="limits.min" [max]="limits.max" (ngModelChange)="onChangeTime()" (keydown.enter)="validate()">
      </app-time-input>
    </div>

    <div class="time-input">
      <label>Heure de départ : </label>
      <app-time-input [(ngModel)]="times.end" [minuteGap]="periode.intervalleMinHoraire" [picker]="true"
        [min]="limits.min" [max]="limits.max" (ngModelChange)="onChangeTime()" (keydown.enter)="validate()">
      </app-time-input>
    </div>

    <mat-error *ngIf="error">
      <span [ngSwitch]="error">
        <span *ngSwitchCase="'etablissement_times_constraint'">
          {{ 'reservation.error_type.etablissement_times_constraint'|trans:limits }}
        </span>
        <span *ngSwitchCase="'minutes_interval'">
          {{ 'reservation.error_type.minutes_interval'|trans:{'interval': periode.intervalleMinHoraire} }}
        </span>
        <span *ngSwitchDefault>{{ ('reservation.error_type.' + error)|trans }}</span>
      </span>
    </mat-error>
  </div>
</div>

<div mat-dialog-actions align="right">
  <button mat-raised-button color="primary-lighter" (click)="validate()" [disabled]="error">
    Valider
  </button>
</div>
