import { OnInit, Component } from '@angular/core';
import { AuthenticationService } from '@app/services';
import { Router } from '@angular/router';

const storageAccountType = 'remember-account-type';

@Component({
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.logout();
    localStorage.setItem(storageAccountType, this.authService.getAccountType());
    this.router.navigate(['login']);
  }
}
